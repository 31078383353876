<template>
  <v-row class="ma-2">
    <v-col
      :cols="$store.state.recruitingView.candidateView.drawer ? 4 : 12"
      class="my-n2"
    >
      <v-progress-linear
        indeterminate
        class="my-3"
        height="15"
        v-if="!candidateInfo.first_name"
      ></v-progress-linear>
      <div class="text-h4" v-if="candidateInfo.first_name">
        {{ candidateInfo.first_name + " " + candidateInfo.last_name }}
        <v-icon
          color="amber-darken-2"
          @click="updateFavorite(false)"
          v-if="candidateInfo.recruiting_job_candidates[0].favorite"
          >mdi-crown</v-icon
        >
        <v-icon @click="updateFavorite(true)" v-else>mdi-crown-outline</v-icon>
      </div>
    </v-col>
    <v-col cols="6" align="center" v-if="type == 'drawer'">
      <v-pagination
        @click="openCandidate(tabIndex)"
        :length="applications.length"
        v-model="tabIndex"
      ></v-pagination>
    </v-col>
    <v-col cols="2" align="right" class="my-n2" v-if="type == 'drawer'">
      <v-btn
        color="red"
        @click="
          $store.state.candidateView.id = null;
          $store.state.recruitingView.candidateView.drawer = false;
        "
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="12" sm="4" class="separate-scroll-view">
      <v-alert
        type="error"
        v-if="
          candidateInfo.recruiting_job_candidates &&
          candidateInfo.recruiting_job_candidates[1]
        "
      >
        <div>
          {{
            `Doppelte Bewerbung mit Status ${candidateInfo.recruiting_job_candidates[1].recruiting_status.name} für Job ${candidateInfo.recruiting_job_candidates[1].recruiting_jobs.title}`
          }}
        </div>
        <br />
        <div>Soll diese Bewerbung gelöscht werden?</div>
        <v-btn
          color="error"
          icon
          flat
          @click="
            deleteDoubleApplication(
              candidateInfo.recruiting_job_candidates[1].id,
            )
          "
        >
          <v-icon> mdi-delete </v-icon></v-btn
        >
      </v-alert>
      <TaskManagement
        v-if="candidateInfo.id"
        entity="candidate_id"
        :entityId="candidateInfo.id"
        :entityInfo="candidateInfo"
      ></TaskManagement>
      <Application
        v-if="candidateInfo.id && candidateInfo.events"
        :candidateInfo="candidateInfo"
      ></Application>
      <WorkProfile
        v-if="candidateInfo.id"
        :candidateInfo="candidateInfo"
      ></WorkProfile>
      <Profile v-if="candidateInfo.id" :candidateInfo="candidateInfo"></Profile>
    </v-col>
    <v-col cols="12" sm="8" class="separate-scroll-view">
      <v-card>
        <v-tabs v-model="tab" bg-color="primary" grow>
          <v-tab value="preview">Vorschau</v-tab>
          <v-tab value="notes">Notizen</v-tab>
          <v-tab value="emails">Emails</v-tab>
          <v-tab value="events">Termine</v-tab>
          <v-tab value="evaluations">Evaluationen</v-tab>
          <v-tab value="checklist">Checklist</v-tab>
        </v-tabs>
        <v-card-text>
          <v-window v-model="tab">
            <v-window-item value="preview" v-if="candidateInfo.id">
              <PdfPreview
                v-if="candidateInfo.id && pdfCheck"
                :candidateInfo="candidateInfo"
              ></PdfPreview>
            </v-window-item>
            <v-window-item value="notes">
              <Notes
                v-if="
                  candidateInfo.recruiting_job_candidates &&
                  candidateInfo.specialNotes
                "
                :candidateInfo="candidateInfo"
                :users="users"
              ></Notes>
            </v-window-item>
            <v-window-item value="emails">
              <EmailComponent
                v-if="candidateInfo.recruiting_job_candidates"
                :users="users"
                :entityInfo="candidateInfo"
                entity="recruiting_candidates"
                :toAddress="candidateInfo.email"
              ></EmailComponent>
            </v-window-item>
            <v-window-item value="evaluations">
              <Evaluations
                v-if="candidateInfo.statusId"
                :users="users"
                :candidateInfo="candidateInfo"
              ></Evaluations>
            </v-window-item>
            <v-window-item value="events">
              <Calendar
                v-if="candidateInfo.id"
                :entity="candidateInfo"
                :entityId="candidateInfo.id"
                :entityInfo="candidateInfo"
              >
              </Calendar>
            </v-window-item>
            <v-window-item value="checklist">
              <Checklist
                v-if="candidateInfo.id"
                :candidateInfo="candidateInfo"
              ></Checklist>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12"> </v-col>
  </v-row>
</template>

<script setup>
import { mapState } from "vuex";
import TaskManagement from "@/components/Home/TaskManagement.vue";
import Calendar from "@/components/Home/Calendar.vue";
import Application from "@/components/Candidates/Application.vue";
import Profile from "@/components/Candidates/Profile.vue";
import WorkProfile from "@/components/Candidates/WorkProfile.vue";
import Checklist from "@/components/Candidates/Checklist.vue";
import Notes from "@/components/Candidates/Notes.vue";
import EmailComponent from "@/components/CommonComponents/EmailComponent/EmailComponent.vue";
import PdfPreview from "@/components/Candidates/PdfPreview.vue";
import Evaluations from "@/components/Candidates/Evaluations.vue";

const props = defineProps({
  applicationCandidateId: "String",
  applicationJobCandidateId: "String",
  type: "String",
  applications: "Array",
  chosenApplicationIndex: "Number",
});
</script>

<script>
export default {
  data() {
    return {
      candidateInfo: {},
      newCandidate: null,
      candidateId: null,
      jobCandidateId: null,
      users: [],
      tab: "preview",
      pdfCheck: false,
      tabIndex: 1,
    };
  },
  computed: {
    ...mapState({
      candidateChangeCheck: (state) => state.candidateChangeCheck,
    }),
  },
  watch: {
    async candidateChangeCheck(newValue, oldValue) {
      await this.updateNotes();
      this.$store.state.candidateChangeCheck = false;
    },
    async tab(newValue, oldValue) {
      if (newValue == "preview") {
        this.pdfCheck = true;
      } else {
        this.pdfCheck = false;
      }
    },
    beforeRouteUpdate(to, from, next) {
      // Check if the route parameter has changed
      if (to.params.id !== from.params.id) {
        // Fetch data when the parameter changes
        this.getCandidate().then(() => next());
      } else {
        // Continue with the navigation if the parameter hasn't changed
        next();
      }
    },
  },
  methods: {
    openCandidate(index) {
      var application = this.applications[index - 1];
      this.candidateId = application.candidate_id.toString();
      this.jobCandidateId = application.id.toString();
      this.getCandidate();
    },
    async getCandidate() {
      this.candidateInfo = {};
      var candidateId =
        this.type == "drawer" ? this.candidateId : this.$route.params.id;
      if (candidateId) {
        var queryObject = {
          select:
            "*,notes(*,public_users(*),recruiting_activities(*)),employees(*),recruiting_job_candidates!inner(*,recruiting_status(*),recruiting_jobs(*),recruiting_sources(*),notes(*,public_users(*),recruiting_activities(*))),sipgate_calls(*,public_users(*))",
          table: "recruiting_candidates",
          where: [
            {
              type: "eq",
              key: "id",
              value: candidateId,
            },
          ],
        };
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
        response.data[0].recruiting_job_candidates[0].notes =
          response.data[0].recruiting_job_candidates[0].notes.concat(
            response.data[0].notes,
          );
        var jobIndex = 0;
        let calls = await this.addCalls(response.data[0]);
        response.data[0].recruiting_job_candidates[jobIndex].notes =
          response.data[0].recruiting_job_candidates[jobIndex].notes.concat(
            calls,
          );
        response.data[0].recruiting_job_candidates[jobIndex].notes.sort(
          (a, b) =>
            a.modified_at > b.modified_at
              ? -1
              : b.modified_at > a.modified_at
              ? 1
              : 0,
        );
        for (
          var i = 0;
          i < response.data[0].recruiting_job_candidates[jobIndex].notes.length;
          i++
        ) {
          var item =
            response.data[0].recruiting_job_candidates[jobIndex].notes[i];
          item.showDate = await this.$store.dispatch(
            "formatDate",
            new Date(item.modified_at),
          );
        }
        var events = await this.$store.dispatch("checkCalendar", {
          entity: response.data[0],
        });
        response.data[0].events = events;
        this.candidateInfo = response.data[0];
        this.candidateInfo.specialNotes = await this.createSpecialNotes();
        this.candidateInfo.statusId = this.candidateInfo
          .recruiting_job_candidates[0]
          ? this.candidateInfo.recruiting_job_candidates[0].status_id
          : null;
      }
    },
    async addCalls(notes) {
      var calls = [];
      var hangupCauses = {
        normalClearing: "Normaler Anrufabbruch",
        busy: "Besetzt",
        cancel: "Abgebrochen",
        noAnswer: "Keine Antwort",
        congestion: "Technisches Problem",
        notFound: "Nummer nicht gefunden",
        forwarded: "Weitergeleitet",
      };
      notes.sipgate_calls.forEach((call) => {
        if (call.was_answered && call.direction == "in") {
          var action = call.action ? call.action : "Eingeganger Anruf";
          var recruitingActivities = {
            icon: "mdi-phone-incoming",
            color: "green",
          };
          var text = call.text
            ? call.text
            : `${(
                (new Date(call.end).getTime() -
                  new Date(call.start).getTime()) /
                1000 /
                60
              ).toFixed(2)} Minuten Telefonzeit`;
        }
        if (call.was_answered && call.direction == "out") {
          var action = call.action ? call.action : "Ausgehender Anruf";
          var recruitingActivities = {
            icon: "mdi-phone-outgoing",
            color: "green",
          };
          var text = call.text
            ? call.text
            : `${(
                (new Date(call.end).getTime() -
                  new Date(call.start).getTime()) /
                1000 /
                60
              ).toFixed(2)} Minuten Telefonzeit`;
        }
        if (!call.was_answered && call.direction == "in") {
          var action = call.action ? call.action : "Verpasster Anruf";
          var text = call.text ? call.text : null;
          var recruitingActivities = {
            icon: "mdi-phone-missed",
            color: "red",
          };
          if (call.hangup_cause == "forwarded") {
            action = "Weitergeleiteter Anruf";
            recruitingActivities.icon = "mdi-phone-forward";
            recruitingActivities.color = "warning";
          }
        }
        if (!call.was_answered && call.direction == "out") {
          var action = call.action
            ? call.action
            : "Kandidat wurde nicht erreicht";
          var text = call.text ? call.text : null;
          var recruitingActivities = {
            icon: "mdi-phone-missed",
            color: "red",
          };
          if (call.hangup_cause == "forwarded") {
            action = call.action ? call.action : "Weitergeleiteter Anruf";
            var text = call.text ? call.text : null;
            recruitingActivities.icon = "mdi-phone-forward";
            recruitingActivities.color = "warning";
          }
        }
        call = {
          action: action,
          modified_at: call.start,
          public_users: call.public_users,
          recruiting_activities: recruitingActivities,
          text: text,
          sipgate_id: call.sipgate_id,
        };
        calls.push(call);
      });
      return calls;
    },
    async createSpecialNotes() {
      var specialNotes = [];
      var notes = this.candidateInfo.recruiting_job_candidates[0].notes;
      notes.sort((a, b) =>
        a.modified_at > b.modified_at
          ? 1
          : b.modified_at > a.modified_at
          ? -1
          : 0,
      );
      var checkActivityId = null;
      var statusArray = [];
      for (var i = 0; i < notes.length; i++) {
        if (
          notes[i].activity_id &&
          notes[i].activity_id != checkActivityId &&
          notes[i].activity_id != 16 &&
          notes[i].activity_id != 17
        ) {
          specialNotes.push(statusArray);
          statusArray = [];
          checkActivityId = notes[i].activity_id;
        }
        statusArray.push(notes[i]);
        if (i == notes.length - 1) {
          specialNotes.push(statusArray);
        }
      }
      for (var i = 0; i < specialNotes.length; i++) {
        specialNotes[i].sort((a, b) =>
          a.modified_at > b.modified_at
            ? -1
            : b.modified_at > a.modified_at
            ? 1
            : 0,
        );
      }
      specialNotes.reverse();
      return specialNotes;
    },
    async deleteDoubleApplication(id) {
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "recruiting_job_candidates",
        id: id,
      });
      this.getCandidate();
    },
    async updateNotes() {
      var candidateId = this.candidateInfo.id;
      var jobCandidateId = this.candidateInfo.recruiting_job_candidates[0].id;
      var queryObject = {
        table: "notes",
        select: "*,public_users(*),recruiting_activities(*))",
        where: [
          {
            type: "or",
            key: "job_candidate_id",
            value: `candidate_id.eq.${candidateId},job_candidate_id.eq.${jobCandidateId}`,
          },
        ],
      };
      var notes = await this.$store.dispatch("getSupabaseEntity", queryObject);
      var callResponse = await this.$store.dispatch("getSupabaseEntity", {
        table: "sipgate_calls",
        select: "*,public_users(*)",
        where: [
          {
            type: "eq",
            key: "candidate_id",
            value: this.candidateInfo.id,
          },
        ],
      });
      let calls = await this.addCalls({ sipgate_calls: callResponse.data });
      notes = notes.data.concat(calls);
      console.log({ calls: calls, notes: notes });
      notes.sort((a, b) =>
        a.modified_at > b.modified_at
          ? -1
          : b.modified_at > a.modified_at
          ? 1
          : 0,
      );
      notes.forEach(function (item) {
        item.showDate =
          item.modified_at.split("-")[2].split("T")[0] +
          "." +
          item.modified_at.split("-")[1] +
          "." +
          item.modified_at.split("-")[0] +
          " " +
          item.modified_at.split("-")[2].split("T")[1].slice(0, 5);
      });
      this.candidateInfo.recruiting_job_candidates[0].notes = notes;
      this.candidateInfo.specialNotes = await this.createSpecialNotes();
    },
    async updateFavorite(favorite) {
      this.candidateInfo.recruiting_job_candidates[0].favorite = favorite;
      var queryObject = {
        id: this.candidateInfo.recruiting_job_candidates[0].id,
        table: "recruiting_job_candidates",
        payload: {
          favorite: favorite,
        },
      };
      var response = await this.$store.dispatch(
        "updateSupabaseEntity",
        queryObject,
      );
    },
  },

  async created() {
    this.tabIndex = this.chosenApplicationIndex + 1;
    if (this.applicationCandidateId) {
      this.candidateId = this.applicationCandidateId;
    }
    if (this.applicationJobCandidateId) {
      this.jobCandidateIdId = this.applicationJobCandidateId;
    }
    this.$store.state.emailDialog = false;
    this.$store.state.cvDataObject = {};
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_users",
      where: [{ type: "is", key: "active", value: true }],
    });
    this.users = response.data;
    this.tab = "preview";
    await this.getCandidate();
  },
};
</script>
<style>
.full-height-card {
  height: 80vh;
  overflow: auto;
  overflow-y: auto;
}
.separate-scroll-view {
  max-height: 88vh;
  overflow-y: auto;
}
</style>
