<template>
  <v-row v-for="emailGroup in chosenRole.emailGroups">
    <v-col cols="4">
      {{ emailGroup.email }}
    </v-col>
    <v-col cols="8">
      <v-icon
        @click="handleCheck(emailGroup)"
        :icon="
          emailGroup.is_checked
            ? 'mdi-checkbox-marked'
            : 'mdi-checkbox-blank-outline'
        "
      >
      </v-icon>
    </v-col>
  </v-row>
</template>

<script setup>
const props = defineProps({
  chosenRole: Object,
  index: Number,
});
</script>
<script>
export default {
  data() {
    return {
      emailGroups: [],
    };
  },
  methods: {
    handleCheck(emailGroup) {
      emailGroup.is_checked = !emailGroup.is_checked;
      var queryObject = {
        table: "public_user_role_email_group_matching",
        payload: {
          public_user_role_id: this.chosenRole.id,
          email_group_id: emailGroup.id,
          is_checked: emailGroup.is_checked,
        },
      };
      this.$store.dispatch("upsertSupabaseEntity", queryObject);
    },
  },
  created() {},
};
</script>
