<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-btn
        icon
        class="ml-4 my-2"
        @click="newAction.activated = !newAction.activated"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-card
        v-bind="props"
        :elevation="isHovering ? '10' : '2'"
        class="ma-4"
        rounded="xl"
        v-if="newAction.activated"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12">
              <v-select
                variant="outlined"
                :items="noteTemplates"
                label="Vorlagen"
                item-title="name"
                return-object
                @update:modelValue="loadNoteTemplate"
              ></v-select>
              <v-text-field
                variant="outlined"
                label="Betreff"
                v-model="newAction.object.action"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="8">
              <v-card :class="newAction.edit ? null : 'quill-container'">
                <QuillEditor
                  toolbar="essential"
                  v-model:content="newAction.object.text"
                  contentType="html"
                  placeholder="Notes"
                />
              </v-card>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                variant="outlined"
                multiple
                :items="users"
                item-title="full_name"
                item-value="id"
                chips
                v-model="newAction.object.listeners"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="saveNote(newAction.object)"
            :loading="newAction.loading"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
  <div
    v-if="
      candidateInfo.recruiting_job_candidates[0] &&
      candidateInfo.recruiting_job_candidates[0].notes.length > 0
    "
    v-for="noteBlock in candidateInfo.specialNotes"
  >
    <v-divider></v-divider>
    <div class="text-h6 text-bold" v-if="noteBlock.length > 0">
      {{ noteBlock[noteBlock.length - 1].action }}
    </div>
    <v-timeline side="end">
      <v-timeline-item
        v-for="note in noteBlock"
        :key="note.id"
        :dot-color="
          note.recruiting_activities ? note.recruiting_activities.color : 'grey'
        "
        :icon="
          note.recruiting_activities
            ? note.recruiting_activities.icon
            : 'mdi-note'
        "
        class="mobile"
      >
        <v-hover>
          <template v-slot:default="{ isHovering, props }">
            <v-card v-bind="props" :elevation="isHovering ? '10' : '2'">
              <v-card-title>
                <v-row>
                  <v-col cols="12" v-if="note.edit">
                    <v-select
                      variant="outlined"
                      :items="noteTemplates"
                      label="Vorlagen"
                      item-title="name"
                      return-object
                      @update:modelValue="
                        note.action = $event.name;
                        note.text = $event.text;
                        note.is_positive = $event.is_positive;
                      "
                    ></v-select>
                  </v-col>
                  <v-col align="left">
                    <span v-if="!note.edit">{{ note.action }}</span>
                    <v-text-field v-else v-model="note.action"></v-text-field>
                  </v-col>
                  <v-col align="right">
                    <v-btn
                      color="error"
                      size="small"
                      flat
                      v-if="note.edit"
                      @click="deleteNote(note.id)"
                      :loading="newAction.loading"
                    >
                      <v-icon>mdi-delete</v-icon></v-btn
                    >
                    <v-btn
                      size="small"
                      flat
                      v-if="note.edit"
                      @click="saveNote(note, note.id, note.sipgate_id)"
                      :loading="newAction.loading"
                    >
                      <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="isHovering || note.edit"
                      size="small"
                      flat
                      @click="note.edit = !note.edit"
                    >
                      <v-icon
                        :icon="note.edit ? 'mdi-pencil-off' : 'mdi-pencil'"
                      ></v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-subtitle>
                <v-row>
                  <v-col v-if="note.public_users">
                    <v-icon>mdi-account</v-icon
                    >{{
                      note.public_users.first_name +
                      " " +
                      note.public_users.last_name
                    }}
                  </v-col>
                  <v-col v-else> Kein User eingetragen </v-col>
                  <v-col>
                    <v-icon>mdi-calendar</v-icon>{{ note.showDate }}
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <div v-if="note.edit" class="pa-2" align="center">
                <Quill-Editor
                  toolbar="essential"
                  v-model:content="note.text"
                  contentType="html"
                  placeholder="Notes"
                />
                <v-btn-toggle v-model="note.is_positive" class="mt-2">
                  <v-btn :value="true" color="success">
                    <v-icon>mdi-thumb-up</v-icon>
                  </v-btn>
                  <v-btn :value="false" color="error">
                    <v-icon>mdi-thumb-down</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-card-text v-else v-html="note.text"></v-card-text>
              <v-col
                align="center"
                v-if="note.is_positive != null && !note.edit"
                class="mt-n4"
              >
                <v-btn
                  size="small"
                  icon
                  :color="note.is_positive ? 'success' : 'error'"
                >
                  <v-icon
                    :icon="note.is_positive ? 'mdi-thumb-up' : 'mdi-thumb-down'"
                  ></v-icon>
                </v-btn>
              </v-col>
            </v-card>
          </template>
        </v-hover>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script setup>
import { QuillEditor } from "@vueup/vue-quill";
const props = defineProps({
  candidateInfo: Object,
  users: Array,
});
</script>

<script>
export default {
  data() {
    return {
      noteRoleId: null,
      newAction: {
        loading: false,
        object: {},
      },
      noteTemplates: [],
    };
  },
  methods: {
    async deleteNote(id) {
      this.newAction.loading = true;
      var queryObject = {
        table: "notes",
        id: id,
      };
      this.$store.dispatch("deleteSupabaseEntity", queryObject);
      this.$store.state.candidateChangeCheck = true;
      this.newAction.loading = false;
      this.newAction.activated = false;
    },
    async loadNoteTemplate(template) {
      this.newAction.object.text = template.text;
      this.newAction.object.action = template.action;
      this.newAction.object.is_positive = template.is_positive;
    },
    async saveNote(payload, id, sipgate_id) {
      this.newAction.loading = true;
      payload.modified_at = new Date().toISOString();
      payload.owner_id = this.$store.state.userInfo.id;
      payload.role_id = this.noteRoleId;
      if (id || this.newAction.id) {
        payload = {
          text: payload.text,
          action: payload.action,
          is_positive: payload.is_positive,
        };
        var queryObject = {
          id: id || this.newAction.id,
          table: "notes",
          payload: payload,
        };
        var response = await this.$store.dispatch(
          "updateSupabaseEntity",
          queryObject,
        );
      } else if (sipgate_id) {
        payload = {
          text: payload.text,
          action: payload.action,
          is_positive: payload.is_positive,
          sipgate_id: sipgate_id,
        };
        var queryObject = {
          table: "sipgate_calls",
          payload: payload,
        };
        var response = await this.$store.dispatch(
          "upsertSupabaseEntity",
          queryObject,
        );
      } else {
        var queryObject = {
          table: "notes",
          payload: payload,
        };
        this.newAction.id = await this.$store.dispatch(
          "createSupabaseEntity",
          queryObject,
        );
      }
      this.newAction.loading = false;
      this.$store.state.candidateChangeCheck = true;
      this.newAction = {
        loading: false,
        object: {},
      };
    },
    async getNoteTemplates() {
      var queryObject = {
        select: "*",
        table: "note_templates",
        where: [
          {
            type: "eq",
            key: "type",
            value: "candidates",
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.noteTemplates = response.data;
    },
  },
  async created() {
    var defaultNoteRole = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_user_note_roles",
      where: [{ type: "is", key: "is_recruiting_default", value: true }],
    });
    this.noteRoleId = defaultNoteRole.data[0].id;
    this.newAction.object = {
      job_candidate_id: this.candidateInfo.recruiting_job_candidates[0].id,
      //owner_id: this.$store.state.userInfo.id,
    };
    await this.getNoteTemplates();
  },
};
</script>
