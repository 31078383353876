<template>
  <v-tabs v-model="tab">
    <v-tab value="dates">Datumswerte</v-tab>
    <v-tab value="checks">Mitarbeiterchecks</v-tab>
  </v-tabs>
  <v-window v-model="tab">
    <v-window-item value="dates">
      <EmployeeDates />
    </v-window-item>
    <v-window-item value="checks">
      <EmployeeChecks />
    </v-window-item>
  </v-window>
</template>

<script setup>
import EmployeeDates from "@/components/Reports/EmployeeDates.vue";
import EmployeeChecks from "@/components/Reports/EmployeeChecks.vue";
import { ref } from "vue";
let tab = ref(null);
</script>
