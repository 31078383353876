<template>
  Gruppierung nach
  <v-btn
    :color="!groupBy[0] ? 'green' : null"
    size="small"
    @click="groupBy = []"
    >Keine</v-btn
  >
  <v-btn
    :color="groupBy[0] && groupBy[0].key == 'category' ? 'green' : null"
    size="small"
    @click="groupBy = [{ key: 'category' }]"
    >Kategorie</v-btn
  >
  <v-btn
    :color="groupBy[0] && groupBy[0].key == 'employees.name' ? 'green' : null"
    size="small"
    @click="groupBy = [{ key: 'employees.name' }]"
    >Mitarbeiter</v-btn
  >
  <v-data-table-virtual
    :items="toCheckDocuments"
    :headers="toCheckDocumentHeaders"
    :group-by="groupBy"
  >
    <template v-slot:item.name="{ item }">
      <v-btn
        color="blue-lighten-1"
        size="x-small"
        rounded="xl"
        @click="openFile(null, null, item)"
        >{{ item.name }}</v-btn
      >
    </template>
    <template v-slot:item.employees.name="{ item }">
      <router-link
        target="_blank"
        :to="`/Mitarbeiter/${item.employee_id}`"
        size="small"
        variant="elevated"
        color="blue-grey"
        >{{ item.employees.name }}</router-link
      >
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        :loading="loading"
        icon
        size="x-small"
        color="success"
        @click="acceptDocument(item)"
      >
        <v-icon>mdi-thumb-up</v-icon>
      </v-btn>
      <v-btn
        :loading="loading"
        icon
        size="x-small"
        color="error"
        @click="deleteFile(item)"
      >
        <v-icon>mdi-thumb-down</v-icon>
      </v-btn>
    </template>
  </v-data-table-virtual>
</template>

<script setup>
const props = defineProps({
  employeeInfo: Object,
  users: Array,
  role: Object,
});
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      categories: [],
      groupBy: [{ key: "employees.name" }],
      toCheckDocuments: [],
      toCheckDocumentHeaders: [
        { title: "Name", value: "name" },
        { title: "Datum", value: "showDate" },
        { title: "Kategorie", value: "category" },
        { title: "Mitarbeiter", value: "employees.name" },
        { title: "Vorgeschlagen von", value: "public_users.full_name" },
        { title: "Übernehmen", value: "actions" },
      ],
    };
  },
  methods: {
    async getFiles() {
      this.categories = [];
      this.toCheckDocuments = [];
      this.allCategories = [];
      var idArray = [];
      var employeeId = this.role.employeeId;
      var userEmployeeId = this.role.userEmployeeId;

      this.role.documentCategories.forEach((item) => {
        let checkRole = item.public_user_role_document_matching;
        for (var i = 0; i < checkRole.length; i++) {
          if (
            (checkRole[i].show_own_data && userEmployeeId == employeeId) ||
            checkRole[i].show_nightshift_data ||
            checkRole[i].show_all_data
          ) {
            idArray.push(item.id);
          }
          if (
            (checkRole[i].suggest_own_data && userEmployeeId == employeeId) ||
            checkRole[i].suggest_nightshift_data ||
            checkRole[i].suggest_all_data
          ) {
            var checkName = item.name;
            if (!this.allCategories.find((item) => item.name == checkName)) {
              this.allCategories.push(item);
            }
          }
        }
      });

      var queryObject = {
        select:
          "*,employee_documents!inner(*,employees(name),public_users!public_employee_documents_suggested_by_fkey(full_name))",
        table: "employee_document_categories",
        where: [
          {
            type: "in",
            key: "id",
            value: idArray,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      for (var i = 0; i < response.data.length; i++) {
        var category = response.data[i];
        category.documents = [];
        for (var j = 0; j < category.employee_documents.length; j++) {
          var document = category.employee_documents[j];
          var date = new Date(document.created_at);
          var editable = false;
          var right = this.role.documentCategories.find(
            (item) => category.id == item.id,
          );
          for (
            var k = 0;
            k < right.public_user_role_document_matching.length;
            k++
          ) {
            var checkRight = right.public_user_role_document_matching[k];
            if (
              checkRight.edit_all_data ||
              checkRight.edit_nightshift_data ||
              (checkRight.edit_own_data &&
                this.role.employeeId == this.role.userEmployeeId)
            ) {
              var editable = true;
            }
          }
          document.showDate = await this.$store.dispatch("formatDate", date);
          if (document.suggested_by && !document.is_request_accepted) {
            document.category = category.name;
            if (editable) {
              this.toCheckDocuments.push(document);
            }
          } else {
            category.documents.push(document);
          }
        }
      }
      this.categories = response.data.filter(
        (item) => item.documents.length > 0,
      );
    },
    async openFile(event, row, item) {
      if (item) {
        var file = item;
      } else {
        var file = row.item;
      }

      var queryObject = {
        bucket: "employees",
        file: `${file.employee_id}/${file.category_id}/${file.name}`,
      };
      var url = await this.$store.dispatch("getStorageFileLink", queryObject);
      window.open(url, "_blank");
    },
    async acceptDocument(item) {
      this.loading = true;
      var queryObject = {
        table: "employee_documents",
        id: item.id,
        payload: {
          is_request_accepted: true,
          created_by: item.suggested_by,
          accepted_by: this.$store.state.userInfo.id,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      this.getFiles();
      this.loading = false;
    },
    async deleteFile(file) {
      this.loading = true;
      var fileName =
        file.employee_id + "/" + file.category_id + "/" + file.name;
      await this.$store.dispatch("removeStorageFile", {
        bucket: "employees",
        file: fileName,
      });
      await this.$store.dispatch("deleteSupabaseEntity", {
        table: "employee_documents",
        id: file.id,
      });
      await this.getFiles();
      this.loading = false;
    },
  },
  async created() {
    await this.getFiles();
  },
};
</script>
