<template>
  <h3 class="ma-2">Aufgabenkategorien</h3>
  <v-card>
    <v-card-text class="mt-3">
      <v-row v-for="(category, index) in categories" class="my-n10">
        <v-col cols="12" class="mt-4 mb-n4">
          {{ category.tasks?.length + " Aufgaben" }} /
          {{ category.notes?.length + " Notizen" }}
        </v-col>
        <v-col cols="12">
          <v-text-field
            variant="outlined"
            density="compact"
            append-icon="mdi-delete"
            :disabled="category.tasks?.length > 0 || category.notes?.length > 0"
            @click:append="deleteCategory(category, index)"
            v-model="category.name"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-col align="center">
      <v-btn @click="categories.push({ name: null, tasks: [], notes: [] })">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn @click="saveCategories()" class="ml-2" :loading="loading">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-col>
  </v-card>
</template>

<script setup>
import draggable from "vuedraggable";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      categories: [],
    };
  },
  methods: {
    async getCategories() {
      var queryObject = {
        table: "task_categories",
        select: "*,notes(id),tasks(id)",
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.sort((a, b) =>
        a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
      );
      this.categories = response.data;
    },
    async deleteCategory(job, index) {
      if (job.id) {
        await this.$store.dispatch("deleteSupabaseEntity", {
          id: job.id,
          table: "task_categories",
        });
      }
      this.categories.splice(index, 1);
    },
    async saveCategories() {
      var table = "task_categories";
      var payload = this.categories;
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(payload));
      var upsertArray = [];
      for (var i = 0; i < payload.length; i++) {
        delete payload[i].tasks;
        delete payload[i].notes;
        if (payload[i].id) {
          upsertArray.push(payload[i]);
        } else {
          var queryObject = {
            table: table,
            payload: payload[i],
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
        }
      }
      var queryObject = {
        table: table,
        payload: upsertArray,
      };
      await this.$store.dispatch("upsertSupabaseEntity", queryObject);
      await this.getCategories();
      this.loading = false;
    },
  },
  async mounted() {
    this.getCategories();
  },
};
</script>
