<template>
  <v-row>
    <v-col cols="3">
      <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
      <v-list>
        <v-list-item
          @click="removeEmployee(user)"
          :title="user.public_users.full_name"
          :subtitle="user.public_users.occupation"
          v-for="user in chosenRole.public_user_role_matching"
        >
          <template v-slot:prepend>
            <v-icon icon="mdi-account"></v-icon>
          </template>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col cols="9">
      <v-autocomplete
        v-if="!loading"
        @update:modelValue="addEmployee"
        :items="chosenRole.users"
        item-title="full_name"
        item-value="id"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script setup>
const props = defineProps({
  chosenRole: Object,
  index: Number,
});
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      roles: [],
      users: [],
      tab: null,
    };
  },
  methods: {
    async getRoles() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        select:
          "*,public_user_role_section_matching(*),public_user_role_matching(*,public_users(full_name,id,occupation))",
        table: "public_user_roles",
      });
      this.roles = response.data;
      for (var i = 0; i < this.roles.length; i++) {
        this.roles[i].sections = await this.getSectionData(
          this.roles[i].id,
          this.roles[i].public_user_role_section_matching,
        );
        this.roles[i].users = this.getUsers(this.roles[i]);
      }

      this.chosenRole.public_user_role_matching =
        this.roles[this.index].public_user_role_matching;
      this.chosenRole.users = this.getUsers(this.roles[this.index]);
      this.chosenRole.users.forEach(function (item) {
        item.full_name = `${item.last_name}, ${item.first_name}`;
      });
      this.tab = "members";
    },
    getUsers(role) {
      var users = [];
      for (var i = 0; i < this.users.length; i++) {
        var user = this.users[i];
        user.full_name = `${user.last_name}, ${user.first_name}`;
        var pushToArray = true;
        for (var j = 0; j < role.public_user_role_matching.length; j++) {
          var checkUser = role.public_user_role_matching[j];
          if (user.id == checkUser.user_id) {
            var pushToArray = false;
          }
        }
        if (pushToArray) {
          users.push(user);
        }
      }
      return users;
    },
    async getSectionData(roleId, matching) {
      var queryObject = {
        select: "*,public_user_role_section_matching(*)",
        table: "employee_data_sections",
        where: [
          {
            type: "eq",
            key: "public_user_role_section_matching.role_id",
            value: roleId,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      response.data.forEach(function (item) {
        var matching = item.public_user_role_section_matching[0];
        item.rights = {
          show: [
            {
              key: "show_own_data",
              value: matching ? matching.show_own_data : false,
              label: "Eigene",
            },
            {
              key: "show_nightshift_data",
              value: matching ? matching.show_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "show_all_data",
              value: matching ? matching.show_all_data : false,
              label: "Alle",
            },
          ],
          suggest: [
            {
              key: "suggest_own_data",
              value: matching ? matching.suggest_own_data : false,
              label: "Eigene",
            },
            {
              key: "suggest_nightshift_data",
              value: matching ? matching.suggest_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "suggest_all_data",
              value: matching ? matching.suggest_all_data : false,
              label: "Alle",
            },
          ],
          edit: [
            {
              key: "edit_own_data",
              value: matching ? matching.edit_own_data : false,
              label: "Eigene",
            },
            {
              key: "edit_nightshift_data",
              value: matching ? matching.edit_nightshift_data : null,
              label: "Nachtschicht",
            },
            {
              key: "edit_all_data",
              value: matching ? matching.edit_all_data : false,
              label: "Alle",
            },
          ],
        };
      });
      return response.data;
    },
    async addEmployee(item) {
      this.loading = true;
      var queryObject = {
        table: "public_user_role_matching",
        payload: {
          user_id: item,
          role_id: this.chosenRole.id,
        },
      };
      await this.$store.dispatch("createSupabaseEntity", queryObject);
      await this.getRoles();
      item = null;
      this.loading = false;
    },
    async removeEmployee(user) {
      this.loading = true;
      var queryObject = {
        table: "public_user_role_matching",
        id: user.id,
      };
      this.$store.dispatch("deleteSupabaseEntity", queryObject);
      await this.getRoles();
      this.loading = false;
    },
  },
  async created() {
    var response = await this.$store.dispatch("getSupabaseEntity", {
      table: "public_users",
      where: [{ type: "is", key: "active", value: true }],
    });
    this.users = response.data;
    this.getRoles();
  },
};
</script>
