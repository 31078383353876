<template>
  <TaskManagement ownView="true" :showAll="showAll"></TaskManagement>
</template>

<script setup>
import TaskManagement from "@/components/Home/TaskManagement.vue";
</script>
<script>
export default {
  data() {
    return {
      showAll: false,
    };
  },
  methods: {
    async checkRights() {
      var queryObject = {
        select: "*,public_user_roles!inner(*)",
        table: "public_user_role_matching",
        where: [
          { type: "eq", key: "user_id", value: this.$store.state.userInfo.id },
          {
            type: "is",
            key: "public_user_roles.can_see_all_tasks",
            value: true,
          },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      if (response.data.length > 0) {
        this.showAll = true;
      }
    },
  },
  async mounted() {
    await this.checkRights();
  },
};
</script>

<style>
/* Add any custom styles for your table here */
</style>
