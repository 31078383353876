<template>
  <v-dialog
    v-model="$store.state.employeeView.dialog"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card color="grey-lighten-3">
      <ProfileView
        v-if="$store.state.employeeView.dialog"
        :employeeId="employeeObject.id"
      ></ProfileView
    ></v-card>
  </v-dialog>
  <v-row class="mx-2">
    <v-col cols="12" md="2">
      <v-autocomplete
        clearable
        v-if="roleRights.can_change_user"
        variant="outlined"
        @update:modelValue="getEmployees(chosenUserId)"
        :items="$store.state.employeeFilter.users"
        label="Anmelden als"
        item-title="full_name"
        return-object
        v-model="chosenUser"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="4" class="mb-n4" align="center">
      <v-text-field
        label="Suche"
        single-line
        variant="outlined"
        prepend-inner-icon="mdi-magnify"
        v-model="tableSearch"
      ></v-text-field>
    </v-col>
    <v-col cols="12" md="4" class="mb-n4" align="center">
      <v-autocomplete
        label="Hotels"
        variant="outlined"
        :append-icon="
          $store.state.employeeFilter.showHotels ? 'mdi-eye' : 'mdi-eye-off'
        "
        @click:append="hideHotels()"
        multiple
        :items="$store.state.employeeFilter.hotels"
        item-title="name"
        v-model="hotelSearch"
        clearable
        @update:modelValue="getEmployees()"
      >
        <template v-slot:append>
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-icon
                @click="
                  $store.state.hideBlockedEmployees =
                    !$store.state.hideBlockedEmployees;
                  getEmployees();
                "
                v-bind="props"
                :icon="
                  $store.state.hideBlockedEmployees
                    ? 'mdi-account'
                    : 'mdi-account-cancel'
                "
              ></v-icon>
            </template>
            <div>
              <v-icon>mdi-account-cancel</v-icon>Gesperrte Mitarbeitern werden
              angezeigt
            </div>
            <div>
              <v-icon>mdi-account</v-icon>Gesperrte Mitarbeitern werden
              versteckt
            </div>
          </v-tooltip>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="12" md="2" class="mb-n4" align="center">
      <v-btn size="x-large" @click="emailDialog = true">Email senden</v-btn>
      <v-dialog v-model="emailDialog" width="1000">
        <MassEmail :employees="employees"></MassEmail>
      </v-dialog>
    </v-col>
    <v-col cols="12">
      <v-data-table
        @click:row="openEmployee"
        :items="employees"
        :headers="$store.state.employeeFilter.chosenKeys"
        hover
        items-per-page="25"
        :loading="loading"
        :search="tableSearch"
        density="compact"
      >
        <template v-slot:item.name="{ item }">
          {{ item.first_name + " " + item.last_name }}
        </template>
        <template v-slot:item.hotels="{ item }">
          <template v-for="hotel in item.hotels">
            <v-tooltip
              v-if="hotel.id"
              :text="`${hotel.count} Schichten ingesamt, ${hotel.senior_count} Senior und ${hotel.clerk_count} Clerk`"
            >
              <template v-slot:activator="{ props }">
                <v-chip
                  class="ma-1"
                  size="small"
                  :color="setColor(hotel.count)"
                  @click.stop="$router.push({ path: '/Hotels/' + hotel.id })"
                  v-bind="props"
                >
                  {{ hotel.name }}
                  <v-rating
                    readonly
                    class="ml-1 mb-1"
                    density="compact"
                    size="x-small"
                    length="5"
                    v-model="hotel.level"
                  ></v-rating>
                  <v-chip size="x-small" v-if="hotel.is_senior">Senior</v-chip>
                  <v-chip size="x-small" v-if="hotel.is_clerk">Clerk</v-chip>
                  <v-chip
                    size="x-small"
                    variant="elevated"
                    color="error"
                    v-if="hotel.is_blocked"
                    >Gesperrt</v-chip
                  >
                </v-chip>
              </template>
            </v-tooltip>
          </template>

          <template v-for="hotel in item.hotels" v-if="false">
            <v-chip
              v-if="
                hotel.name &&
                (hotelSearch.length == 0 || hotelObject[hotel.name])
              "
              size="small"
              >{{ hotel.name
              }}<v-chip size="x-small" v-if="hotel.is_clerk">Clerk</v-chip>
              <v-chip size="x-small" v-if="hotel.is_senior"
                >Senior</v-chip
              ></v-chip
            >
          </template>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script setup>
import ProfileView from "@/components/Employees/Profile.vue";
import MassEmail from "@/components/Employees/MassEmail.vue";
import { mapState } from "vuex";
</script>
<script>
export default {
  data() {
    return {
      start: true,
      loading: false,
      employees: [],
      employeeObject: {},
      emailDialog: false,
      tableSearch: null,
      hotelObject: {},
      hotelSearch: [],
      roleRights: {},
      chosenUser: null,
    };
  },
  computed: {
    ...mapState({
      chosenKeys: (state) => state.employeeFilter.chosenKeys,
    }),
  },
  watch: {
    chosenKeys: {
      async handler(newValue, oldValue) {
        if (!this.start) {
          this.getEmployees();
        }
      },
      deep: true,
      async: true,
    },
  },
  methods: {
    async getHotels() {
      this.$store
        .dispatch("getSupabaseEntity", {
          table: "hotels",
          order: {
            key: "name",
            ascending: true,
          },
        })
        .then((response) => {
          this.$store.state.employeeFilter.hotels = response.data;
        });
    },
    setColor(count) {
      if (count > 20) {
        return "green-darken-2";
      } else if (count > 10) {
        return "orange-darken-2";
      } else {
        return "grey-darken-2";
      }
    },
    setLevel(count) {
      var level = 0;
      if (count > 40) {
        level = 5;
      } else if (count > 30) {
        level = 4;
      } else if (count > 20) {
        level = 3;
      } else if (count > 10) {
        level = 2;
      } else if (count > 0) {
        level = 1;
      }
      return level;
    },
    async getEmployees() {
      this.loading = true;
      await this.$store.dispatch("sleep");
      var sectionShow = this.$store.state.employeeFilter.role;
      if (!sectionShow) {
        var sectionShow = await this.$store.dispatch("checkRole", {
          chosenUserId: this.chosenUser ? this.chosenUser.id : null,
          employeeId: this.chosenUser ? this.chosenUser.employees[0].id : null,
          userEmployeeId: this.chosenUser
            ? this.chosenUser.employees[0].id
            : null,
        });
        this.$store.state.employeeFilter.role = sectionShow;
      }

      var triggerQuery = false;

      if (
        sectionShow.showAllEmployees ||
        sectionShow.showNightshiftEmployees ||
        sectionShow.showOwnEmployees
      ) {
        var queryObject = { table: "get_employees" };
        var triggerQuery = true;
      }
      if (
        !sectionShow.showAllEmployees &&
        sectionShow.showNightshiftEmployees
      ) {
        queryObject.where = [
          {
            type: "or",
            value: `department_id.eq.2,email.eq.${this.$store.state.userInfo.email}`,
          },
        ];
      }
      if (
        !sectionShow.showAllEmployees &&
        !sectionShow.showNightshiftEmployees &&
        sectionShow.showOwnEmployees
      ) {
        queryObject.where = [
          {
            type: "eq",
            key: "email",
            value: this.chosenUser
              ? this.chosenUser.email
              : this.$store.state.userInfo.email,
          },
        ];
      }

      if (triggerQuery) {
        var response = await this.$store.dispatch(
          "getSupabaseEntity",
          queryObject,
        );
      } else {
        var response = { data: [] };
      }
      var shifts = await this.$store.dispatch("getSupabaseEntity", {
        table: "aggregate_shifts",
      });
      var selections = this.$store.state.employeeFilter.selections;
      this.loading = false;
      response.data.forEach((can) => {
        can.id = can.employee_id;
        var shiftCheckObject = {};
        can.hotels.forEach((hotel) => {
          if (hotel.id) {
            shiftCheckObject[hotel.id] = true;
          }
        });

        can.hotels.forEach((hotel) => {
          hotel.count = 0;
          hotel.senior_count = 0;
          hotel.clerk_count = 0;
          hotel.training_count = 0;
          hotel.level = 0;

          shifts.data.forEach((shift) => {
            if (shift.employee_id == can.id) {
              if (!shiftCheckObject[shift.hotel_id]) {
                can.hotels.push({
                  id: shift.hotel_id,
                  name: shift.hotel_name,
                  count: shift.count,
                  senior_count: shift.senior_count,
                  clerk_count: shift.clerk_count,
                  training_count: shift.training_count,
                  level: this.setLevel(shift.count),
                });
                shiftCheckObject[shift.hotel_id] = true;
              }
              if (shift.hotel_id == hotel.id) {
                hotelExists = true;
                hotel.count = shift.count;
                hotel.senior_count = shift.senior_count;
                hotel.clerk_count = shift.clerk_count;
                hotel.training_count = shift.training_count;
                hotel.level = this.setLevel(shift.count);
              }
            }
          });
        });
        can.hotels.sort((a, b) => b.count - a.count);
        can.data_matching.forEach(function (item) {
          if (
            sectionShow.sectionRights[item.section_id]?.showable ||
            item.key_label == "status"
          ) {
            if (item.type == "text" || item.type == "selection") {
              can[item.key_label] = item.value_text;
            } else if (item.type == "date") {
              can[item.key_label] = item.value_date;
            } else if (item.type == "boolean") {
              can[item.key_label] = item.value_bool;
            } else if (item.type == "id") {
              var id = item.value_id;
              var table = selections[item.selection_table];
              if (table) {
                var valueObject = table.find((item) => item.id === id);
                if (valueObject) {
                  can[item.key_label] = valueObject[item.item_name];
                } else {
                  can[item.key_label] = null;
                }
              }
            } else if (item.type == "number") {
              can[item.key_label] = item.value_number;
            }
          }
        });
        delete can.data_matching;
      });
      response.data.sort((a, b) => a.last_name + b.last_name);

      var hotelExists = this.$store.state.employeeFilter.chosenKeys.find(
        (item) => item.value == "hotels",
      );
      if (!hotelExists && this.$store.state.employeeFilter.showHotels) {
        this.$store.state.employeeFilter.chosenKeys.push({
          title: "Hotels",
          value: "hotels",
        });
      }
      var list = this.filterEmployees(response.data);
      this.employees = list;
    },
    hideHotels() {
      this.$store.state.employeeFilter.showHotels =
        !this.$store.state.employeeFilter.showHotels;
      if (this.$store.state.employeeFilter.showHotels) {
        this.$store.state.employeeFilter.chosenKeys.push({
          title: "Hotels",
          value: "hotels",
        });
      } else {
        this.$store.state.employeeFilter.chosenKeys =
          this.$store.state.employeeFilter.chosenKeys.filter(
            (item) => item.value != "hotels",
          );
      }
    },
    filterEmployees(list) {
      var filter = this.$store.state.employeeFilter.chosenKeys;
      list = list.filter(function (item) {
        var returnTrue = true;
        for (var i = 0; i < filter.length; i++) {
          var key = filter[i];
          var checkTrue = false;
          if (
            key.key_value &&
            key.key_value.length > 0 &&
            key.type != "boolean"
          ) {
            var filterValue = key.key_value;
            for (var j = 0; j < filterValue.length; j++) {
              var filterCheckItem = filterValue[j];
              var itemValue = item[key.key_label] ? item[key.key_label] : null;
              if (filterCheckItem == itemValue) {
                checkTrue = true;
              }
            }
          } else if (key.key_value && key.type == "boolean") {
            var itemValue = item[key.key_label] ? item[key.key_label] : null;
            var filterValue = key.key_value;
            if (itemValue == filterValue) {
              checkTrue = true;
            }
          } else {
            checkTrue = true;
          }
          if (!checkTrue) {
            returnTrue = false;
          }
        }
        return returnTrue;
      });

      if (this.hotelSearch.length > 0 || this.hotelSearch.name) {
        this.hotelObject = {};
        this.hotelSearch.forEach((item) => {
          this.hotelObject[item] = true;
        });

        list = list.filter((item) => {
          var hotels = item.hotels;
          for (var j = 0; j < hotels.length; j++) {
            if (this.hotelObject[hotels[j].name]) {
              if (
                !hotels[j].is_blocked ||
                !this.$store.state.hideBlockedEmployees
              ) {
                return true;
              }
            }
          }
        });
      }
      return list;
    },
    openEmployee(event, row) {
      this.employeeObject = row.item;
      this.$store.state.employeeView.dialog = true;
      //this.$router.push({ path: "/Mitarbeiter/" + this.employeeObject.id });
    },
    async checkIfCanChangeUser() {
      var roles = await this.$store.dispatch("getSupabaseEntity", {
        select: "*,public_user_roles!inner(*)",
        table: "public_user_role_matching",
        where: [
          { type: "eq", key: "user_id", value: this.$store.state.userInfo.id },
          { type: "is", key: "public_user_roles.can_change_user", value: true },
        ],
      });
      if (roles.data.length > 0) {
        this.roleRights.can_change_user = true;
      }
    },
    getSelections() {
      this.$store
        .dispatch("getSupabaseEntity", {
          table: "departments",
        })
        .then((response) => {
          this.$store.state.employeeFilter.selections.departments =
            response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "employment_types",
        })
        .then((response) => {
          this.$store.state.employeeFilter.selections.employment_types =
            response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "organisations",
        })
        .then((response) => {
          this.$store.state.employeeFilter.selections.organisations =
            response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "employee_teams",
        })
        .then((response) => {
          this.$store.state.employeeFilter.selections.employee_teams =
            response.data;
        });

      this.$store
        .dispatch("getSupabaseEntity", {
          table: "cities",
        })
        .then((response) => {
          this.$store.state.employeeFilter.selections.cities = response.data;
        });
    },
  },
  async created() {
    if (this.$store.state.employeeFilter.hotels.length == 0) {
      this.getHotels();
    }

    if (this.$store.state.employeeFilter.chosenKeys.length <= 2) {
      this.getSelections();
    }
    if (this.$store.state.employeeFilter.users.length == 0) {
      var queryObject = {
        select: "id,email,full_name,employees(id)",
        table: "public_users",
      };
      this.$store.state.employeeFilter.users = (
        await this.$store.dispatch("getSupabaseEntity", queryObject)
      ).data;
    }

    await this.getEmployees();
    this.checkIfCanChangeUser();

    this.start = false;
  },
};
</script>
