<template>
  <v-form ref="form" v-model="valid">
    <div>
      <!-- Copy and Save Row -->
      <v-row>
        <v-col cols="10" md="5" v-if="trainingForm.length === 0">
          <v-autocomplete
            label="Kopieren von"
            :items="hotelsWithTrainingForm"
            item-title="name"
            item-value="hotel_id"
            v-model="selectedHotelToCopyFrom"
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="2" md="1" v-if="trainingForm.length === 0">
          <v-btn icon @click="copyFormOfOtherHotel(selectedHotelToCopyFrom)">
            <v-icon>mdi-content-copy</v-icon>
            <v-tooltip activator="parent" location="bottom"
              >Formular von Hotel kopieren und unten einsetzen</v-tooltip
            >
          </v-btn>
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          md="3"
          align="end"
          v-if="trainingForm.filter((category) => !category.delete).length > 2"
        >
          <v-btn
            @click="submitForm"
            :color="!valid ? 'grey' : 'success'"
            :loading="submitting"
            :disabled="submitting"
          >
            <v-icon class="mr-1">mdi-content-save</v-icon>
            <v-tooltip activator="parent" location="top" v-if="!valid">
              Ein oder mehrere Felder sind leer
            </v-tooltip>
            Speichern
          </v-btn>
        </v-col>
      </v-row>

      <v-card
        v-for="(category, index) in trainingForm"
        :key="category.selectedCategory?.id"
        class="my-3"
        elevation="5"
      >
        <v-card-text v-if="!category.delete">
          <!-- Category Row -->
          <v-row>
            <v-col cols="9" xs="8" md="4">
              <v-combobox
                v-model="category.selectedCategory"
                v-model:search="categoryInput"
                label="Kategorie"
                :items="categories"
                item-title="name"
                item-value="id"
                :hide-no-data="!categoryInput"
                :readonly="!!category.selectedCategory?.id"
                return-object
                :rules="[
                  (v) => !!v?.name.trim() || 'Kategorie ist erforderlich',
                ]"
                @update:modelValue="
                  (value) => onCategoryModelUpdate(value, index)
                "
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      <kbd>↵ Enter</kbd> drücken, um
                      <strong>{{ categoryInput }}</strong> hinzuzufügen
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-spacer />
            <v-col cols="3" xs="2" md="1">
              <v-btn @click="deleteCategory(index)">
                <v-icon>mdi-delete</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Gesamte Kategorie Löschen
                </v-tooltip>
              </v-btn>
            </v-col>
          </v-row>

          <!-- headers -->
          <v-row v-if="category.training_hotel_tasks.length > 0">
            <!-- Empty Column -->
            <v-col cols="4" class="hidden-sm-and-down"></v-col>

            <!-- Header for First Points -->
            <v-col cols="2" md="1">
              <span>Ja</span>
            </v-col>

            <!-- Header for Second Points -->
            <v-col cols="2" md="1">
              <span class="break-word">Mit Dienstanweisung</span>
            </v-col>

            <!-- Empty Column -->
            <v-col cols="2" md="1" class="mx-n4"></v-col>

            <!-- Header for Third Points -->
            <v-col cols="2" md="1">
              <span>Mit Prüfer</span>
            </v-col>

            <!-- Empty Column -->
            <v-col cols="2" md="1" class="mx-n4"></v-col>

            <!-- Header for Fourth Points -->
            <v-col cols="2" md="1">
              <span>Nein</span>
            </v-col>
          </v-row>

          <!-- Tasks -->
          <div v-for="(task, taskIndex) in category.training_hotel_tasks">
            <v-row
              align="center"
              :key="task.selectedTask?.id"
              v-if="!task.delete"
              class="my-n4"
            >
              <!-- Task Name -->
              <v-col cols="8" md="4" class="px-0 mr-0 d-flex align-top">
                <v-btn
                  icon
                  size="small"
                  flat
                  class="mr-1"
                  @click="deleteTask(index, taskIndex)"
                >
                  <v-icon>mdi-close</v-icon>
                  <v-tooltip activator="parent" location="bottom"
                    >Aufgabe Löschen
                  </v-tooltip>
                </v-btn>
                <v-combobox
                  v-model="task.selectedTask"
                  v-model:search="taskInput"
                  label="Aufgabe"
                  class="pr-3"
                  density="compact"
                  :items="
                    getTaskOptionsByCategoryId(category.selectedCategory?.id)
                  "
                  item-title="name"
                  item-value="id"
                  :hide-no-data="!taskInput"
                  :readonly="!!task.selectedTask?.id"
                  return-object
                  hide-selected
                  :rules="[
                    (v) => !!v?.name.trim() || 'Aufgabe ist erforderlich',
                  ]"
                  @update:modelValue="
                    (value) =>
                      onTaskModelUpdate({
                        value,
                        categoryIndex: index,
                        taskIndex,
                      })
                  "
                  @keydown.enter="
                    onEnterTask({
                      categoryIndex: index,
                      taskIndex,
                    })
                  "
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        <kbd>↵ Enter</kbd> drücken, um
                        <strong>{{ taskInput }}</strong> hinzuzufügen
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>

              <v-divider
                vertical
                class="vertical-divider hidden-sm-and-down"
              ></v-divider>

              <v-col cols="4" md="2" class="hidden-md-and-up">
                <v-checkbox :label="'nur in finaler Abnahme'"></v-checkbox>
              </v-col>

              <!-- Points and Checkboxes -->
              <!-- First 2 Text Fields -->
              <v-col cols="2" md="1">
                <v-text-field
                  density="compact"
                  v-model="
                    task.training_result_choice_points.find(
                      (point) => point.training_result_choice_id === 1,
                    ).points
                  "
                  type="number"
                ></v-text-field>
              </v-col>
              <v-divider vertical class="vertical-divider"></v-divider>
              <v-col cols="2" md="1">
                <v-text-field
                  density="compact"
                  v-model="
                    task.training_result_choice_points.find(
                      (point) => point.training_result_choice_id === 2,
                    ).points
                  "
                  type="number"
                ></v-text-field>
              </v-col>
              <v-divider vertical class="vertical-divider"></v-divider>

              <!-- Last 4 Columns: Icon Checkbox and Text Fields Alternating -->
              <v-col cols="2" md="1" class="mx-n4">
                <div class="icon-checkbox-wrapper">
                  <v-icon>mdi-alert</v-icon>
                  <v-checkbox
                    class="mt-n4"
                    v-model="
                      task.training_result_choice_points.find(
                        (point) => point.training_result_choice_id === 3,
                      ).is_crucial
                    "
                  >
                    <v-tooltip activator="parent" location="bottom"
                      >Sicherheitsrelevante Aufgabe?</v-tooltip
                    ></v-checkbox
                  >
                </div>
              </v-col>
              <v-col cols="2" md="1">
                <v-text-field
                  density="compact"
                  v-model="
                    task.training_result_choice_points.find(
                      (point) => point.training_result_choice_id === 3,
                    ).points
                  "
                  type="number"
                ></v-text-field>
              </v-col>
              <v-divider vertical class="vertical-divider"></v-divider>

              <v-col cols="2" md="1" class="mx-n4">
                <div class="icon-checkbox-wrapper">
                  <v-icon>mdi-alert</v-icon>
                  <v-checkbox
                    class="mt-n4"
                    v-model="
                      task.training_result_choice_points.find(
                        (point) => point.training_result_choice_id === 4,
                      ).is_crucial
                    "
                  >
                    <v-tooltip activator="parent" location="bottom"
                      >Sicherheitsrelevante Frage?</v-tooltip
                    ></v-checkbox
                  >
                </div>
              </v-col>
              <v-col cols="2" md="1">
                <v-text-field
                  density="compact"
                  v-model="
                    task.training_result_choice_points.find(
                      (point) => point.training_result_choice_id === 4,
                    ).points
                  "
                  type="number"
                ></v-text-field>
              </v-col>
              <v-spacer />
              <v-col cols="12" md="2" class="hidden-sm-and-down">
                <v-checkbox
                  :label="'nur in finaler Abnahme'"
                  v-model="task.is_only_final"
                ></v-checkbox>
              </v-col>
              <v-divider
                class="horizontal-divider mb-6 hidden-md-and-up"
                :thickness="2"
              ></v-divider>
            </v-row>
          </div>

          <!-- Add Task Button -->
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn size="x-small" icon @click="addTask(index)" class="ml-n2">
                <v-icon>mdi-plus</v-icon>
                <v-tooltip activator="parent" location="bottom"
                  >Neue Aufgabe hinzufügen</v-tooltip
                >
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>

    <v-row no-gutters>
      <v-col cols="12" md="5" class="mb-2">
        <v-btn @click="addCategory" color="primary"
          >Neue Kategorie hinzufügen</v-btn
        >
      </v-col>
      <v-spacer />
      <v-col cols="12" md="3" :align="mdAndUp ? 'end' : 'start'">
        <v-btn
          v-if="trainingForm.length > 0"
          @click="submitForm"
          :color="!valid ? 'grey' : 'success'"
          :loading="submitting"
          :disabled="submitting"
        >
          <v-icon class="mr-1">mdi-content-save</v-icon>
          <v-tooltip activator="parent" location="top" v-if="!valid">
            Ein oder mehrere Felder sind leer
          </v-tooltip>
          Speichern
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
  <v-snackbar v-model="snackbar.isOpen" timeout="3000" :color="snackbar.color">
    {{ snackbar.text }}
  </v-snackbar>
</template>

<script setup>
import { useDisplay } from "vuetify";

const { mdAndUp } = useDisplay();

const props = defineProps({
  hotelId: Number,
  employeeId: Number,
});
</script>

<script>
export default {
  data() {
    return {
      isClientSide: false,
      submitting: false,
      form: null,
      valid: true,
      categories: [],
      trainingForm: [],
      allTasks: [],
      hotelsWithTrainingForm: [],
      categoryInput: "",
      taskInput: "",
      selectedHotelToCopyFrom: null,
      snackbar: {
        isOpen: false,
        text: "",
        color: null,
      },
    };
  },
  methods: {
    async initializeForm(hotelIdToCopyFrom = null) {
      const hotelToUse = hotelIdToCopyFrom ?? this.hotelId;
      const isViewHotel = hotelToUse === this.hotelId;

      let formConfigurations = null;
      let allCategoriesResponse = null;
      let allTasksOptionsResponse = null;
      let allHotelIdsWithTrainingForm = null;

      try {
        const formStructure = await this.$store.dispatch("getSupabaseEntity", {
          select:
            "*,training_hotel_tasks!inner(*,training_hotel_task_results(*,intermediary:training_result_choice_points!intermediary_training_result_choice_point_id(points,is_crucial,training_result_choices(name)),final:training_result_choice_points!final_training_result_choice_point_id(points,is_crucial,training_result_choices(name))),training_result_choice_points(*,training_result_choices(name)),training_tasks(name))",
          table: "training_categories",
          where: [
            {
              type: "eq",
              key: "training_hotel_tasks.hotel_id",
              value: this.hotelId ?? hotelIdToCopyFrom,
            },
            { type: "is", key: "training_hotel_tasks.deleted_at", value: null },
          ],
        });

        formConfigurations = formStructure.data?.map((category) => {
          return {
            selectedCategory: {
              id: category.id,
              name: category.name,
            },
            delete: false,
            training_hotel_tasks: category.training_hotel_tasks.map((task) => {
              return {
                id: isViewHotel ? task.id : null,
                selectedTask: {
                  id: task.task_id,
                  name: task.training_tasks.name,
                },
                order: task.order,
                is_only_final: task.is_only_final,
                created_by_id: isViewHotel
                  ? task.created_by_id
                  : this.employeeId,
                delete: false,
                training_result_choice_points:
                  task.training_result_choice_points.map((choice) => {
                    return {
                      id: isViewHotel ? choice.id : null,
                      ...(isViewHotel && { training_hotel_task_id: task.id }),
                      training_result_choice_id:
                        choice.training_result_choice_id,
                      points: choice.points,
                      is_crucial: choice.is_crucial,
                    };
                  }),
              };
            }),
          };
        });

        allCategoriesResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            select: "*",
            table: "training_categories",
          },
        );

        allTasksOptionsResponse = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            select: "*",
            table: "training_tasks",
          },
        );

        allHotelIdsWithTrainingForm = await this.$store.dispatch(
          "getSupabaseEntity",
          {
            select: "hotel_id, name",
            table: "training_hotels",
          },
        );
      } catch (error) {
        console.error(error);
        this.snackbar.text = "Fehler beim Laden des Formulars";
        this.snackbar.color = "error";
        this.snackbar.isOpen = true;
      }

      return {
        formConfigurations,
        allCategoriesResponse,
        allTasksOptionsResponse,
        allHotelIdsWithTrainingForm,
      };
    },
    addCategory() {
      this.trainingForm.push({
        id: null,
        selectedCategory: {
          id: null,
          name: "",
        },
        training_hotel_tasks: [],
      });
    },
    deleteCategory(index) {
      if (this.trainingForm[index]) {
        this.trainingForm[index].delete = true;
      }
    },
    onCategoryModelUpdate(value, index) {
      if (typeof value === "string") {
        // User entered a new category
        this.trainingForm[index].selectedCategory = {
          id: null,
          name: value,
        };
      } else if (value && value.id) {
        // User selected an existing category
        this.trainingForm[index].selectedCategory = value;
      } else {
        // User cleared the input
        this.trainingForm[index].selectedCategory = {
          id: null,
          name: "",
        };
      }
    },
    addTask(categoryIndex) {
      const currentCategory = this.trainingForm[categoryIndex];
      const previousTaskChoicePoints =
        currentCategory.training_hotel_tasks[
          currentCategory.training_hotel_tasks.length - 1
        ]?.training_result_choice_points;

      this.trainingForm[categoryIndex].training_hotel_tasks.push({
        id: null,
        selectedTask: {
          id: null,
          name: "",
        },
        order: null,
        is_only_final: false,
        delete: false,
        created_by_id: this.employeeId,
        training_result_choice_points: [
          {
            id: null,
            points:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 1,
              )?.points ?? null,
            is_crucial:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 1,
              )?.is_crucial ?? false,
            training_result_choice_id: 1,
          },
          {
            id: null,
            points:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 2,
              )?.points ?? null,
            is_crucial:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 2,
              )?.is_crucial ?? false,
            training_result_choice_id: 2,
          },
          {
            id: null,
            points:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 3,
              )?.points ?? null,
            is_crucial:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 3,
              )?.is_crucial ?? false,
            training_result_choice_id: 3,
          },
          {
            id: null,
            points:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 4,
              )?.points ?? null,
            is_crucial:
              previousTaskChoicePoints?.find(
                (point) => point.training_result_choice_id === 4,
              )?.is_crucial ?? false,
            training_result_choice_id: 4,
          },
        ],
      });
    },
    deleteTask(categoryIndex, taskIndex) {
      if (
        this.trainingForm[categoryIndex] &&
        this.trainingForm[categoryIndex].training_hotel_tasks[taskIndex]
      ) {
        this.trainingForm[categoryIndex].training_hotel_tasks[
          taskIndex
        ].delete = true;
      }
    },
    onTaskModelUpdate({ value, categoryIndex, taskIndex }) {
      if (typeof value === "string") {
        this.trainingForm[categoryIndex].training_hotel_tasks[
          taskIndex
        ].selectedTask = {
          id: null,
          name: value,
        };
      } else if (value && value.id) {
        // User selected an existing task
        this.trainingForm[categoryIndex].training_hotel_tasks[
          taskIndex
        ].selectedTask = value;
      } else {
        // User cleared the input
        this.trainingForm[categoryIndex].training_hotel_tasks[
          taskIndex
        ].selectedTask = {
          id: null,
          name: "",
        };
      }
    },
    onEnterTask({ categoryIndex, taskIndex }) {
      if (!this.taskInput) return;
      this.onTaskModelUpdate({
        value: this.taskInput,
        categoryIndex,
        taskIndex,
      });
      this.taskInput = "";
    },
    getTaskOptionsByCategoryId(categoryId) {
      return this.allTasks.filter((task) => task.category_id === categoryId);
    },
    async copyFormOfOtherHotel(hotelIdToCopyFrom) {
      const {
        formConfigurations,
        allCategoriesResponse,
        allTasksOptionsResponse,
      } = await this.initializeForm(hotelIdToCopyFrom);

      this.trainingForm = formConfigurations;
      this.categories = allCategoriesResponse?.data || [];
      this.allTasks = allTasksOptionsResponse.data || [];
    },
    async refreshForm() {
      const {
        formConfigurations,
        allCategoriesResponse,
        allTasksOptionsResponse,
      } = await this.initializeForm();

      this.trainingForm = formConfigurations;
      this.categories = allCategoriesResponse?.data || [];
      this.allTasks = allTasksOptionsResponse.data || [];
    },
    submitForm() {
      if (this.form) {
        this.form.validate();
      }
      if (this.valid) {
        this.saveFormConfigurations();
      }
    },
    async deleteRecords() {
      for (const category of this.trainingForm) {
        if (category.delete && category.selectedCategory?.id) {
          for (const task of category.training_hotel_tasks) {
            if (task.id) {
              await this.$store.dispatch("updateSupabaseEntity", {
                table: "training_hotel_tasks",
                payload: { deleted_at: new Date().toISOString() },
                id: task.id,
              });
            }
          }
        }
      }

      for (const category of this.trainingForm) {
        if (!category.training_hotel_tasks && category.delete) continue;

        category.training_hotel_tasks
          .filter((task) => task.delete && task.id)
          .forEach(async (task) => {
            await this.$store.dispatch("updateSupabaseEntity", {
              table: "training_hotel_tasks",
              payload: { deleted_at: new Date().toISOString() },
              id: task.id,
            });
          });
      }

      this.trainingForm = this.trainingForm
        .filter((category) => !category.delete)
        .map((category) => ({
          ...category,
          training_hotel_tasks: category.training_hotel_tasks.filter(
            (task) => !task.delete,
          ),
        }));
    },
    async insertNewCategoriesAndTasks() {
      const newCategories = this.trainingForm
        .filter((category) => !category.selectedCategory?.id)
        .map((category) => ({
          name: category.selectedCategory.name.trim(),
        }));

      if (newCategories.length) {
        const { data: insertedCategories, error } = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "training_categories",
            payload: newCategories,
            returnFullResponse: true,
          },
        );

        if (error) {
          throw new Error(error.message);
        }

        insertedCategories.forEach((insertedCategory) => {
          this.trainingForm.find(
            (category) =>
              category.selectedCategory.name === insertedCategory.name,
          ).selectedCategory.id = insertedCategory.id;
        });
      }

      const newTasks = this.trainingForm.flatMap((category) => {
        return category.training_hotel_tasks
          .filter((task) => !task.selectedTask.id)
          .map((task) => ({
            name: task.selectedTask.name.trim(),
            category_id: category.selectedCategory.id,
          }));
      });

      if (newTasks.length) {
        const { data: insertedTasks } = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "training_tasks",
            payload: newTasks,
            returnFullResponse: true,
          },
        );

        insertedTasks.forEach((insertedTask) => {
          this.trainingForm
            .find(
              (category) =>
                category.selectedCategory.id === insertedTask.category_id,
            )
            .training_hotel_tasks.find(
              (task) => task.selectedTask.name === insertedTask.name,
            ).selectedTask.id = insertedTask.id;
        });
      }
    },
    async updateHotelTrainingTasksAndPoints() {
      const hotelTrainingTasks = this.trainingForm.flatMap((category) => {
        return category.training_hotel_tasks.map((task, index) => ({
          ...(task.id && { id: task.id }),
          hotel_id: this.hotelId,
          category_id: category.selectedCategory.id,
          task_id: task.selectedTask.id,
          is_only_final: task.is_only_final,
          order: index + 1,
          created_by_id: task.created_by_id,
        }));
      });

      let newHotelTaskResponse;

      if (hotelTrainingTasks.length) {
        await this.$store.dispatch("upsertSupabaseEntity", {
          table: "training_hotel_tasks",
          payload: hotelTrainingTasks.filter((task) => task.id),
        });

        newHotelTaskResponse = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "training_hotel_tasks",
            payload: hotelTrainingTasks.filter((task) => !task.id),
            returnFullResponse: true,
          },
        );

        if (newHotelTaskResponse.error) {
          throw new Error(newHotelTaskResponse.error.message);
        }
      }

      const choicePoints = this.trainingForm.flatMap((category) => {
        return category.training_hotel_tasks.flatMap((task) => {
          return task.training_result_choice_points.map((choice) => ({
            ...(choice.id && { id: choice.id }),
            training_hotel_task_id:
              task.id ??
              newHotelTaskResponse.data.find(
                (updatedHotelTask) =>
                  updatedHotelTask.category_id ===
                    category.selectedCategory.id &&
                  updatedHotelTask.task_id === task.selectedTask.id,
              ).id,
            training_result_choice_id: choice.training_result_choice_id,
            points: choice.points,
            is_crucial: choice.is_crucial,
          }));
        });
      });

      if (choicePoints.length) {
        const { error: upsertError } = await this.$store.dispatch(
          "upsertSupabaseEntity",
          {
            table: "training_result_choice_points",
            payload: choicePoints.filter((point) => point.id),
          },
        );

        const { error: insertError } = await this.$store.dispatch(
          "createSupabaseEntity",
          {
            table: "training_result_choice_points",
            payload: choicePoints.filter((point) => !point.id),
            returnFullResponse: true,
          },
        );

        if (upsertError) {
          throw new Error(upsertError.message);
        }

        if (insertError) {
          throw new Error(insertError.message);
        }
      }
    },
    async saveFormConfigurations() {
      try {
        this.submitting = true;
        await this.deleteRecords();
        await this.insertNewCategoriesAndTasks();
        await this.updateHotelTrainingTasksAndPoints();
        await this.refreshForm();
        this.snackbar.text = "Formular wurde erfolgreich gespeichert";
        this.snackbar.color = "success";
        this.snackbar.isOpen = true;
      } catch (error) {
        console.error(error);
        this.snackbar.text = "Fehler beim Speichern des Formulars";
        this.snackbar.color = "error";
        this.snackbar.isOpen = true;
      } finally {
        this.submitting = false;
      }
    },
  },
  async created() {
    this.isClientSide = true;
    const {
      formConfigurations,
      allCategoriesResponse,
      allTasksOptionsResponse,
      allHotelIdsWithTrainingForm,
    } = await this.initializeForm();

    this.categories = allCategoriesResponse?.data || [];
    this.trainingForm = formConfigurations || [];
    this.allTasks = allTasksOptionsResponse.data || [];
    this.hotelsWithTrainingForm = allHotelIdsWithTrainingForm.data || [];
  },
};
</script>

<style scoped>
.icon-checkbox-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vertical-divider {
  height: 50px;
  margin-bottom: 20px;
  align-self: center;
}

.break-word {
  word-break: break-all;
}
</style>
