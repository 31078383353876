<template>
  Gruppierung nach
  <v-btn
    :color="!groupBy[0] ? 'green' : null"
    size="small"
    @click="groupBy = []"
    >Keine</v-btn
  >
  <v-btn
    :color="groupBy[0] && groupBy[0].key == 'keyName' ? 'green' : null"
    size="small"
    @click="groupBy = [{ key: 'keyName' }]"
    >Feld</v-btn
  >
  <v-btn
    :color="groupBy[0] && groupBy[0].key == 'employees.name' ? 'green' : null"
    size="small"
    @click="groupBy = [{ key: 'employees.name' }]"
    >Mitarbeiter</v-btn
  >
  <v-data-table-virtual
    v-if="taskHistory.length > 0"
    :items="taskHistory"
    :headers="headers"
    :group-by="groupBy"
  >
    <template v-slot:item.employees.name="{ item }">
      <router-link
        target="_blank"
        :to="`/Mitarbeiter/${item.employees.id}`"
        size="small"
        variant="elevated"
        color="blue-grey"
        >{{ item.employees.name }}</router-link
      >
    </template>
    <template v-slot:item.old_value="{ item }">
      <v-chip
        v-if="item.old_value"
        size="small"
        variant="elevated"
        color="blue-grey"
        >{{
          item.old_value_name ? item.old_value_name : item.old_value
        }}</v-chip
      >
    </template>
    <template v-slot:item.new_value="{ item }">
      <v-chip
        v-if="item.new_value"
        size="small"
        variant="elevated"
        color="green"
        >{{
          item.new_value_name ? item.new_value_name : item.new_value
        }}</v-chip
      >
    </template>
    <template v-slot:item.actions="{ item }">
      <template v-if="item.is_request_accepted == null && item.requested_by_id">
        <div>
          <router-link v-if="item.key_id == 17" to="/Settings/Admin"
            >Kann nur in der Adminkonsole geändert werden</router-link
          >
        </div>
        <v-btn
          :loading="loading"
          size="x-small"
          color="success"
          @click="updateValue(item, 'positive')"
          icon
          v-if="!item.employee_data_keys.is_blocked"
          :disabled="!item.editable"
          ><v-icon>mdi-thumb-up</v-icon></v-btn
        >
        <v-btn
          :loading="loading"
          size="x-small"
          color="error"
          @click="updateValue(item, 'negative')"
          icon
          :disabled="
            !item.editable ||
            (item.employee_data_keys.is_blocked &&
              !$store.state.userInfo.userRights.is_admin)
          "
          ><v-icon>mdi-thumb-down</v-icon></v-btn
        >
      </template>
    </template>
  </v-data-table-virtual>
</template>

<script setup>
const props = defineProps({
  employeeInfo: Object,
  users: Array,
  role: Object,
});
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      inviteUserLoading: false,
      groupBy: [{ key: "keyName" }],
      history: [],
      taskHistory: [],
      headers: [
        { title: "Name", value: "employees.name" },
        { title: "Feld", value: "keyName" },
        { title: "Vorgeschlagen von", value: "requestedByName" },
        { title: "Alter Wert", value: "old_value" },
        { title: "Neuer Wert", value: "new_value" },
        { title: "Erstellt am", value: "showDate" },
        { title: "Geplant für", value: "plannedShowDate" },
        { title: "Übernehmen", value: "actions" },
      ],
    };
  },
  methods: {
    async getHistory() {
      this.history = [];
      this.taskHistory = [];
      var queryObject = {
        select:
          "*,employees(id,name),employee_data_keys(selection_table,key_name_ger,section_id,is_blocked),changedByUser:public_employee_data_history_changed_from_id_fkey(id,full_name),requestedByUser:public_employee_data_history_requested_by_id_fkey(id,full_name)",
        table: "employee_data_history",
        where: [
          { type: "is", key: "is_request_accepted", value: null },
          { type: "not", key: "requested_by_id", value: null },
          {
            type: "in",
            key: "employee_data_keys.section_id",
            value: this.role.sections,
          },
        ],
        order: {
          key: "created_at",
          ascending: false,
        },
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      for (var i = 0; i < response.data.length; i++) {
        var item = response.data[i];
        if (item.employee_data_keys.selection_table) {
          var table = item.employee_data_keys.selection_table;
          var values = [];
          if (item.old_value) {
            values.push(item.old_value);
          }
          if (item.new_value) {
            values.push(item.new_value);
          }
          var queryObject = {
            select: "*",
            table: table,
            where: [
              {
                type: "in",
                key: "id",
                value: values,
              },
            ],
          };
          var ids = await this.$store.dispatch(
            "getSupabaseEntity",
            queryObject,
          );
          ids.data.forEach((id) => {
            if (id.id == item.old_value) {
              item.old_value_name = id.name;
            } else {
              item.new_value_name = id.name;
            }
          });
        }
        item.keyName = item.employee_data_keys.key_name_ger;
        item.editable =
          this.role.sectionRights[item.employee_data_keys.section_id].editable;
        item.showDate = await this.$store.dispatch(
          "formatDate",
          new Date(item.created_at),
        );
        if (item.to_save_date) {
          item.plannedShowDate = (
            await this.$store.dispatch(
              "formatDate",
              new Date(item.to_save_date),
            )
          ).split(" ")[0];
        }
        if (item.changed_by_id) {
          item.changedByName = item.changedByUser.full_name;
        }
        if (item.requested_by_id) {
          item.requestedByName = item.requestedByUser.full_name;
        }
        if (
          !item.to_save_date ||
          item.accept_from_id ||
          new Date(item.to_save_date).getTime() < new Date().getTime()
        ) {
          this.taskHistory.push(item);
        }
      }
    },
    async updateValue(item, type) {
      this.loading = true;
      var queryObject = {
        table: "employee_data_matching",
        payload: {
          employee_id: item.employee_id,
          key_id: item.key_id,
        },
      };
      queryObject.payload[item.key_type + "_suggestion"] = null;
      if (type == "positive") {
        queryObject.payload[item.key_type] = item.new_value;
        var isRequestAccepted = true;
      } else {
        var isRequestAccepted = false;
      }

      await this.$store.dispatch("upsertSupabaseEntity", queryObject);

      var queryObject = {
        table: "employee_data_history",
        id: item.id,
        payload: {
          is_request_accepted: isRequestAccepted,
        },
      };
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      await this.getHistory();
      this.loading = false;
    },
  },
  created() {
    this.getHistory();
  },
};
</script>
