<template>
  <v-hover>
    <template v-slot:default="{ isHovering, props }">
      <v-card v-bind="props" :elevation="isHovering ? '10' : '2'" rounded="xl">
        <v-card-title>Profil</v-card-title>
        <v-row class="px-6">
          <v-col cols="6" v-if="candidateInfo.indeed_link">
            <v-btn
              v-if="candidateInfo.indeed_link"
              rounded="lg"
              class="mr-1"
              color="#001C40"
              :href="candidateInfo.indeed_link"
              target="_blank"
              >Indeed</v-btn
            >
            <CVUpload :candidateInfo="candidateInfo"></CVUpload>
          </v-col>
          <v-col cols="6" align="right">
            <v-btn-toggle v-model="candidateInfo.gender" mandatory>
              <v-btn :value="false" @click="updateGender()">
                <v-icon>mdi-gender-female</v-icon>
              </v-btn>
              <v-btn :value="true" @click="updateGender()">
                <v-icon>mdi-gender-male</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-col> </v-row
        ><v-row class="px-6">
          <v-col cols="12" sm="6">
            <v-text-field
              class="shrink"
              variant="outlined"
              density="compact"
              label="Vorname"
              v-model="candidateInfo.first_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              variant="outlined"
              density="compact"
              label="Nachname"
              v-model="candidateInfo.last_name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" class="mt-n6">
            <v-text-field
              variant="outlined"
              density="compact"
              label="Email"
              v-model="candidateInfo.email"
              append-icon="mdi-at"
              @input="candidateInfo.email = candidateInfo.email.toLowerCase()"
              @click:append="openEmailLink(candidateInfo.email)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" class="mt-n6">
            <v-text-field
              variant="outlined"
              density="compact"
              label="Telefon"
              v-model="candidateInfo.phone"
              append-icon="mdi-phone"
              @click:append="openPhoneLink(candidateInfo.phone)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="mt-n6">
            <v-text-field
              variant="outlined"
              density="compact"
              label="Stadt"
              v-model="candidateInfo.city"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="mt-n6">
            <v-text-field
              variant="outlined"
              density="compact"
              type="date"
              label="Geburtsdatum"
              v-model="candidateInfo.birth_date"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="mt-n6">
            <v-text-field
              variant="outlined"
              density="compact"
              label="Staatsangehörigkeit"
              v-model="candidateInfo.nationality"
            ></v-text-field>
          </v-col>
          <v-col cols="12" v-if="false">
            <v-textarea
              variant="outlined"
              density="compact"
              label="Trello Text"
              v-model="candidateInfo.trello_description"
              readonly
              rows="6"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            size="small"
            @click="updateProfile()"
            v-if="candidateInfo.first_name && candidateInfo.last_name"
            :loading="loading"
          >
            <span v-if="candidateInfo.id">Update</span>
            <span v-else>Create</span>
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-hover>
</template>

<script setup>
import { mapState } from "vuex";
import CVUpload from "@/components/Candidates/CVUpload.vue";
const props = defineProps({
  candidateInfo: Object,
});
</script>

<script>
export default {
  data() {
    return {
      originalEmail: null,
      loading: false,
      selections: {
        timeModel: [],
        experienceLevel: [],
      },
    };
  },
  computed: {
    ...mapState({
      cvDataObject: (state) => state.cvDataObject,
    }),
  },
  watch: {
    async cvDataObject(newValue, oldValue) {
      if (newValue) {
        this.candidateInfo.gender = newValue.gender;
        this.candidateInfo.first_name = newValue.first_name
          ? newValue.first_name
          : this.candidateInfo.first_name;
        this.candidateInfo.last_name = newValue.last_name
          ? newValue.last_name
          : this.candidateInfo.last_name;
        this.candidateInfo.email = newValue.email;
        this.candidateInfo.phone = newValue.phone;
        this.candidateInfo.birth_date = newValue.birth_date;
        this.candidateInfo.city = newValue.city;
        this.candidateInfo.current_position = newValue.current_position;
        this.candidateInfo.current_company = newValue.current_company;
        this.candidateInfo.german_language_level =
          newValue.german_language_level;
        this.candidateInfo.english_language_level =
          newValue.english_language_level;
      }
    },
  },
  methods: {
    async updateProfile() {
      this.loading = true;
      var payload = JSON.parse(JSON.stringify(this.candidateInfo));
      delete payload.recruiting_job_candidates;
      delete payload.statusId;
      delete payload.events;
      delete payload.specialNotes;
      delete payload.date;
      delete payload.employees;
      delete payload.day;
      delete payload.employeeName;
      delete payload.sipgate_calls;
      delete payload.notes;
      payload.first_name = payload.first_name.trim();
      payload.last_name = payload.last_name.trim();
      payload.email = payload.email.trim();
      payload.modified_at = new Date().toISOString();
      var queryObject = {
        table: "recruiting_candidates",
        id: this.candidateInfo.id,
        payload: payload,
      };
      this.checkNewEmail(payload);
      await this.$store.dispatch("updateSupabaseEntity", queryObject);
      await this.$store.dispatch("sleep", 200);
      this.loading = false;
    },
    async checkNewEmail(payload) {
      var email = payload.email;
      var check = email != this.originalEmail;
      if (check) {
        var events = await this.$store.dispatch("checkCalendar", {
          entity: { email: this.originalEmail },
        });
        for (var i = 0; i < events.length; i++) {
          var event = events[i];
          event.attendees.push({
            email: email,
          });
          event.start = event.start.dateTime;
          event.end = event.end.dateTime;
          await this.$store.dispatch("updateEvent", event);
        }
      }
    },
    updateGender() {
      var queryObject = {
        id: this.candidateInfo.id,
        table: "recruiting_candidates",
        payload: {
          gender: this.candidateInfo.gender,
        },
      };
      this.$store.dispatch("updateSupabaseEntity", queryObject);
    },
    openPhoneLink(phone) {
      const phoneLink = `tel:${phone}`;
      window.open(phoneLink);
    },
    openEmailLink(email) {
      const mailtoLink = `mailto:${email}`;
      window.open(mailtoLink);
    },
  },
  async created() {
    this.originalEmail = JSON.parse(JSON.stringify(this.candidateInfo.email));
  },
};
</script>
