<template>
  <v-row justify="center" class="mt-10">
    <v-col cols="10">
      <v-card>
        <v-card-title>Dateien hochladen</v-card-title>
        <v-card-text>
          <v-btn
            prepend-icon="mdi-table"
            color="green"
            class="ma-3"
            size="small"
            target="_blank"
            href="https://docs.google.com/spreadsheets/d/1WETZLyuLvSSp31sLNteKYfKfx2Dr4N7UoT6fV8i7Cak/edit#gid=2119127330"
            >Umbennungsscript</v-btn
          >
          <v-card>
            <v-file-input
              @change="importFiles"
              multiple
              chips
              label="Dateien hochladen"
              class="custom-file-input"
            ></v-file-input>
          </v-card>
          <v-progress-linear
            v-if="!finished"
            class="my-3"
            height="30px"
            :model-value="progress"
          >
            <strong class="text-white">{{
              uploaded + " von " + fileItems.length
            }}</strong>
          </v-progress-linear>
          <v-alert type="success" v-if="finished" class="my-4">
            Alle Dateien wurden hochgeladen
          </v-alert>
          <v-btn
            :loading="loading"
            size="large"
            class="my-2"
            v-if="fileItems.length > 0"
            @click="uploadFiles()"
            color="indigo-darken-1"
            :disabled="validateItems()"
            >Dateien hochladen</v-btn
          >
          <v-card v-if="failedFileItems.length > 0" type="error" class="my-3">
            <v-row>
              <v-col cols="12">
                <v-alert
                  v-for="item in failedFileItems"
                  :key="item.fileName"
                  type="error"
                  class="my-2"
                  >{{ item.fileName }} konnte nicht hochgeladen werden</v-alert
                >
              </v-col>
            </v-row>
          </v-card>
          <v-data-table :headers="headers" :items="fileItems">
            <template v-slot:item.name="{ item }">
              <v-autocomplete
                v-model="item.fileDataObject.employee_id"
                density="compact"
                variant="outlined"
                class="mt-2"
                :items="employeeList"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
            </template>
            <template v-slot:item.category="{ item }">
              <v-autocomplete
                v-model="item.fileDataObject.category_id"
                density="compact"
                variant="outlined"
                class="mt-2"
                :items="documentCategoryList"
                item-title="name"
                item-value="id"
              ></v-autocomplete>
            </template>
            <template v-slot:item.actions="{ item, index }">
              <v-btn color="red" size="small" icon @click="removeItem(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    tab: null,
    employeeList: [],
    documentCategoryList: [],
    employees: {},
    employeeIds: {},
    documentCategories: {},
    progress: 0,
    uploaded: 0,
    finished: false,
    loading: false,
    files: [],
    fileItems: [],
    failedFileItems: [],
    headers: [
      { title: "Mitarbeiter", value: "name" },
      { title: "Kategorie", value: "category" },
      { title: "Datum", value: "showDate" },
      { title: "FileName", value: "fileName" },
      { tilte: "Aktionen", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.getEmployees();
    this.getDocumentCategories();
  },
  methods: {
    async getEmployees() {
      var queryObject = {
        select: "*,employee_data_matching(*)",
        table: "employees",
        where: [
          { type: "eq", key: "employee_data_matching.key_id", value: 20 },
        ],
      };
      var response = await this.$store.dispatch(
        "getSupabaseEntity",
        queryObject,
      );
      this.employeeList = response.data;
      response.data.forEach((item) => {
        var personalNumber = item.employee_data_matching[0]
          ? item.employee_data_matching[0].value_number
          : null;
        this.employees[personalNumber] = item;
        this.employeeIds[item.id] = item;
      });
    },
    async getDocumentCategories() {
      var response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employee_document_categories",
        order: { key: "name", ascending: true },
      });
      this.documentCategoryList = response.data;
      response.data.forEach((item) => {
        this.documentCategories[item.name] = item;
      });
    },
    async importFiles(event) {
      const datePattern = /\d{8}/;
      this.fileItems = [];
      this.filesLoading = true;
      var files = event.target.files;
      this.files = files;
      for (var i = 0; i < this.files.length; i++) {
        var file = this.files[i];
        var parts = file.name.split("_");
        var personalNumber = parts[0];
        if (personalNumber > 0) {
          var employee = this.employees[personalNumber];
          var categoryName = parts[3]?.normalize();
          var category = this.documentCategories[categoryName];
          var date = new Date();
          var dateCode = file.name.match(datePattern)
            ? file.name.match(datePattern)[0]
            : null;
          if (dateCode) {
            var year = dateCode.slice(4, 8);
            var month = parseInt(dateCode.slice(2, 4)) - 1;
            var day = dateCode.slice(0, 2);
            var date = new Date(year, month, day);
          }
          if (date.getFullYear() < 2000) {
            var year = dateCode.slice(0, 4);
            var month = parseInt(dateCode.slice(4, 6)) - 1;
            var day = dateCode.slice(6, 8);
            var date = new Date(year, month, day);
          }
        } else {
          var parts = file.name.split(" ");
          var category = {};
          var employee = {};
          var date = new Date();
          var dateCode = file.name.match(datePattern)
            ? file.name.match(datePattern)[0]
            : null;
          if (dateCode) {
            var year = dateCode.slice(4, 8);
            var month = parseInt(dateCode.slice(2, 4)) - 1;
            var day = dateCode.slice(0, 2);
            var date = new Date(year, month, day);
          }
          if (date.getFullYear() < 2000) {
            var year = dateCode.slice(0, 4);
            var month = parseInt(dateCode.slice(4, 6)) - 1;
            var day = dateCode.slice(6, 8);
            var date = new Date(year, month, day);
          }
          for (var j = 0; j < parts.length; j++) {
            var part = parts[j].replace(",", " ").trim();
            for (var k = 0; k < this.employeeList.length; k++) {
              if (this.employeeList[k].last_name == part) {
                var employee = this.employeeList[k];
                break;
              }
            }
          }
        }

        var fileName = file.name;
        /*var specialName = parts[5];
        if (specialName) {
          if (parts[0] == parts[5] && fileName.split(specialName)[2]) {
            specialName = specialName + fileName.split(specialName)[2];
          } else if (parts[0] != parts[5] && fileName.split(specialName)[1]) {
            specialName = specialName + fileName.split(specialName)[1];
          }

          fileName = specialName;
        }*/
        //try {
        var object = {
          name: employee ? employee.name : null,
          category: category ? category.name : null,
          date: date,
          fileName: fileName,
          showDate: await this.$store.dispatch("formatDate", date),
          file: this.files[i],
          fileObject: {
            bucket: "employees",
            file: file,
          },
          fileDataObject: {
            category_id: category ? category.id : null,
            name: fileName,
            created_at: date.toISOString(),
            modified_at: date.toISOString(),
            employee_id: employee ? employee.id : null,
            created_by: this.$store.state.userInfo.id,
          },
        };
        /*} catch (err) {
          console.log({
            message: err.message,
            stack: err.stack,
            personalNumber: personalNumber,
            fileName: file.name,
          });
        //}*/

        this.fileItems.push(object);
      }
    },
    validateItems() {
      var disable = false;
      this.fileItems.forEach((item) => {
        if (
          !item.fileDataObject.category_id ||
          !item.fileDataObject.employee_id
        ) {
          disable = true;
        }
      });
      return disable;
    },
    removeItem(index) {
      this.fileItems.splice(index, 1);
    },
    async uploadFiles() {
      this.loading = true;
      this.failedFileItems = [];
      this.finished = false;
      this.progress = 0;
      this.uploaded = 0;
      for (var i = 0; i < this.fileItems.length; i++) {
        var fileUpload = this.fileItems[i].fileObject;
        var fileDataUpload = this.fileItems[i].fileDataObject;
        fileUpload.filePath =
          fileDataUpload.employee_id +
          "/" +
          fileDataUpload.category_id +
          "/" +
          fileDataUpload.name;
        var response = await this.$store.dispatch(
          "uploadStorageFile",
          fileUpload,
        );
        if (response.error) {
          this.failedFileItems.push(this.fileItems[i]);
        } else {
          console.log(response);
          var fileDataUpload = this.fileItems[i].fileDataObject;
          var queryObject = {
            table: "employee_documents",
            payload: fileDataUpload,
          };
          await this.$store.dispatch("createSupabaseEntity", queryObject);
          this.sendEmailToUser(fileDataUpload);
        }

        this.progress = ((i + 1) / this.fileItems.length) * 100;
        this.uploaded = i + 1;
      }
      this.fileItems = [];
      if (this.failedFileItems.length > 0) {
        this.fileItems = this.failedFileItems;
      }
      this.files = [];
      this.finished = true;
      this.loading = false;
    },
    async sendEmailToUser(file) {
      var category = file.category_id;
      var employeeInfo = this.employeeIds[file.employee_id];

      var role = await this.$store.dispatch("getSupabaseEntity", {
        select:
          "*,public_user_roles!inner(*,public_user_role_document_matching!inner(*))",
        table: "public_user_role_matching",
        where: [
          {
            type: "eq",
            key: "user_id",
            value: employeeInfo.user_id,
          },
          {
            type: "eq",
            key: "public_user_roles.public_user_role_document_matching.document_category_id",
            value: category,
          },
        ],
      });
      if (role.data.length > 0) {
        var emailObject = {
          to: employeeInfo.email,
          bcc: "cto@ta-management.de",
          subject: "Neues Dokument in Ihrem Bellcaptain-Portal",
          body: `
            <!DOCTYPE html>
            <html lang="de">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>Neues Dokument</title>
            </head>
            <body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; max-width: 600px; margin: 0 auto; padding: 20px;">
                <table cellpadding="0" cellspacing="0" border="0" width="100%" style="background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 5px;">
                    <tr>
                        <td style="padding: 30px;">
                            <div style="margin-bottom: 20px;">
                                <h1 style="color: #2c3e50; margin: 0 0 20px 0;">Neues Dokument verfügbar</h1>
                            </div>
                            <div style="margin-bottom: 15px;">
                                Hallo ${employeeInfo.first_name} ${employeeInfo.last_name},
                            </div>
                            <div style="margin-bottom: 15px;">
                                Ein neues Dokument wurde für Sie hochgeladen:
                            </div>
                            <div style="margin-bottom: 20px; font-weight: bold; color: #3498db;">
                                ${file.name}
                            </div>
                            <div style="margin-bottom: 15px;">
                                Bitte loggen Sie sich in Ihr Konto ein, um das Dokument einzusehen.
                            </div>
                            <div style="margin-bottom: 15px;">
                                Sie können das Portal unter folgendem Link erreichen:
                            </div>
                            <div style="margin-bottom: 20px;">
                                <a href="https://portal.bellcaptain.de/" style="color: #3498db; text-decoration: underline;">https://portal.bellcaptain.de/</a>
                            </div>
                            <div style="margin-top: 30px;">
                                Mit freundlichen Grüßen,<br>
                                Ihr Bellcaptain Team
                            </div>
                        </td>
                    </tr>
                </table>
            </body>
            </html>
          `,
        };
        const response = await this.$store.dispatch(
          "sendEmailPerBackend",
          emailObject,
        );
      }
    },
  },
};
</script>

<style>
.custom-file-input .v-input__control {
  height: 200px; /* Adjust this value as needed */
}
</style>
