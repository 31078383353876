<template>
  <v-row>
    <v-col v-for="role in noteRoles" cols="6">
      <v-card class="ma-2 py-3" variant="outlined">
        <v-card-title>
          <v-text-field label="Name" v-model="role.name"></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-textarea
            label="Beschreibung"
            v-model="role.description"
          ></v-textarea>
          <v-row class="ma-2">
            <v-switch
              @change="checkDefault(role, 'is_employee_default')"
              color="green"
              v-model="role.is_employee_default"
              label="Default für Mitarbeiter"
            ></v-switch>
            <v-switch
              @change="checkDefault(role, 'is_recruiting_default')"
              color="green"
              v-model="role.is_recruiting_default"
              label="Default für Recruiting"
            ></v-switch>
            <v-switch
              @change="checkDefault(role, 'is_portal_default')"
              color="green"
              v-model="role.is_portal_default"
              label="Default für Portal"
            ></v-switch>
          </v-row>
          <div class="text-h6">Leserechte</div>
          <v-btn
            @click="userRole.checked = !userRole.checked"
            size="small"
            v-for="userRole in role.showRoles"
            :key="userRole.id"
            :color="userRole.checked ? 'indigo' : 'grey'"
          >
            {{ userRole.name }}
          </v-btn>
          <div class="text-h6 mt-4">Nutzrechte</div>
          <v-btn
            @click="userRole.checked = !userRole.checked"
            size="small"
            v-for="userRole in role.showUsingRoles"
            :key="userRole.id"
            :color="userRole.checked ? 'indigo' : 'grey'"
          >
            {{ userRole.name }}
          </v-btn>
          <v-col cols="12" align="center" class="mt-4">
            <v-btn color="success" @click="saveRole(role)" :loading="loading"
              >Rolle speichern</v-btn
            >
          </v-col>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-btn color="success" @click="addRole()">Rolle hinzufügen</v-btn>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
const store = useStore();
let noteRoles = ref([]);
let userRoles = ref([]);
let loading = ref(false);

const getUserRoles = async () => {
  const response = await store.dispatch("getSupabaseEntity", {
    select: "*",
    table: "public_user_roles",
  });
  userRoles.value = response.data;
};

const getNoteRoles = async () => {
  const response = await store.dispatch("getSupabaseEntity", {
    select: "*",
    table: "public_user_note_roles",
    order: {
      key: "id",
      ascending: true,
    },
  });

  response.data.forEach((noteRole) => {
    noteRole.showRoles = [];
    noteRole.showUsingRoles = [];
    userRoles.value.forEach((userRole) => {
      let chosenRoleObject = {
        id: userRole.id,
        name: userRole.name,
        checked: false,
      };
      let chosenUsingRoleObject = {
        id: userRole.id,
        name: userRole.name,
        checked: false,
      };
      noteRole.roles.forEach((role) => {
        if (role === userRole.id) {
          chosenRoleObject.checked = true;
        }
      });
      noteRole.roles_using?.forEach((role) => {
        if (role === userRole.id) {
          chosenUsingRoleObject.checked = true;
        }
      });
      noteRole.showRoles.push(chosenRoleObject);
      noteRole.showUsingRoles.push(chosenUsingRoleObject);
    });
  });
  noteRoles.value = response.data;
};

const addRole = async () => {
  const id = await store.dispatch("createSupabaseEntity", {
    table: "public_user_note_roles",
    payload: {
      name: "",
      description: "",
      is_recruiting_default: false,
      is_employee_default: false,
      is_portal_default: false,
      roles: [],
    },
  });
  noteRoles.value.push({
    id: id,
    name: "",
    description: "",
    is_recruiting_default: false,
    is_employee_default: false,
    is_portal_default: false,
    showRoles: userRoles.value.map((userRole) => {
      return {
        id: userRole.id,
        name: userRole.name,
        checked: false,
      };
    }),
    showUsingRoles: userRoles.value.map((userRole) => {
      return {
        id: userRole.id,
        name: userRole.name,
        checked: false,
      };
    }),
  });
};

const saveRole = async (role) => {
  loading.value = true;
  let payload = JSON.parse(JSON.stringify(role));
  payload.roles = [];
  payload.roles_using = [];
  payload.showRoles.forEach((showRole) => {
    if (showRole.checked) {
      payload.roles.push(showRole.id);
    }
  });
  payload.showUsingRoles.forEach((showRole) => {
    if (showRole.checked) {
      payload.roles_using.push(showRole.id);
    }
  });
  delete payload.showRoles;
  delete payload.showUsingRoles;
  if (role.id) {
    await store.dispatch("upsertSupabaseEntity", {
      table: "public_user_note_roles",
      payload: payload,
    });
  } else {
    await store.dispatch("createSupabaseEntity", {
      table: "public_user_note_roles",
      payload: payload,
    });
  }
  loading.value = false;
};

const checkDefault = (chosenRole, type) => {
  noteRoles.value.forEach((role) => {
    role[type] = false;
  });
  chosenRole[type] = true;
  let upsertArray = [];
  noteRoles.value.forEach((role) => {
    let upsertObject = {
      id: role.id,
    };
    upsertObject[type] = role[type];
    upsertArray.push(upsertObject);
  });
  store.dispatch("upsertSupabaseEntity", {
    table: "public_user_note_roles",
    payload: upsertArray,
  });
};

onMounted(async () => {
  await getUserRoles();
  getNoteRoles();
});
</script>
