// Utilities
import { supabase } from "../supabase";

export default {
  actions: {
    async getNoteRoles(context) {
      var noteRoles = {
        read: [],
        write: [],
      };
      var allNoteRoles = await supabase
        .from("public_user_note_roles")
        .select("*");
      var ownRoles = await supabase
        .from("public_user_role_matching")
        .select("role_id")
        .eq("user_id", context.rootState.userInfo.id);
      var roleSet = new Set();
      ownRoles.data.forEach((role) => {
        roleSet.add(role.role_id);
      });
      allNoteRoles.data.forEach((noteRole) => {
        noteRole.roles.forEach((role) => {
          if (roleSet.has(role)) {
            noteRoles.read.push(noteRole);
          }
        });
        noteRole.roles_using.forEach((role) => {
          if (roleSet.has(role)) {
            noteRoles.write.push(noteRole);
          }
        });
      });
      return noteRoles;
    },
  },
};
