<template>
  <v-card class="ma-2">
    <v-tabs v-model="tab">
      <v-tab value="noteTemplates">Notizvorlagen</v-tab>
      <v-tab value="speedTest">Speedtest</v-tab>
      <v-tab value="googleTriggers">Google Triggers</v-tab>
      <v-tab value="taskCategories">Aufgabenkategorien</v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="noteTemplates">
          <NoteTemplates></NoteTemplates>
        </v-window-item>
        <v-window-item value="speedTest">
          <Speedtest></Speedtest>
        </v-window-item>
        <v-window-item value="googleTriggers">
          <GoogleTriggers></GoogleTriggers>
        </v-window-item>
        <v-window-item value="taskCategories">
          <TaskCategories></TaskCategories>
        </v-window-item>
      </v-window>
    </v-card-text>
  </v-card>
</template>

<script setup>
import NoteTemplates from "@/components/Settings/General/NoteTemplates.vue";
import Speedtest from "@/components/Settings/General/SpeedTest.vue";
import GoogleTriggers from "@/components/Settings/General/GoogleTriggers.vue";
import TaskCategories from "@/components/Settings/General/TaskCategories.vue";
</script>
<script>
export default {
  data() {
    return {
      loading: false,
      tab: "General Settings",
    };
  },
  methods: {},
  async mounted() {},
};
</script>
