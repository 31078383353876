<template>
  <v-card>
    <v-btn color="indigo" class="ma-12" @click="prepareEmail()"
      >Email Dialog öffnen</v-btn
    >
    <EmailDialog
      :receivers="receivers"
      v-if="$store.state.emailDialog"
      :entityInfo="receivers"
      :chosenEmail="chosenEmail"
      entity="employees"
      :portalEmails="portalEmails"
    ></EmailDialog>
    <v-card-text>
      <v-row class="mb-1">
        <v-col align="left" class="mr-9">
          <v-btn
            class="mx-1"
            rounded="lg"
            color="blue-darken-2"
            size="small"
            v-for="all in allFilter"
            @click="checkAll(all)"
            >{{ all.title }}</v-btn
          >
        </v-col>
        <v-col align="right">
          <v-btn color="success" @click="addEmail.dialog = true">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-dialog v-model="addEmail.dialog" width="400">
            <v-card>
              <v-card-title class="bg-indigo">
                <v-row>
                  <v-col>
                    <h3>Mitarbeiter hinzufügen</h3>
                  </v-col>
                  <v-col align="right">
                    <v-btn @click="addEmail.dialog = false" flat color="indigo">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-autocomplete
                  :items="addEmail.allEmployees"
                  item-title="searchName"
                  v-model="addEmail.user"
                  return-object
                ></v-autocomplete>
                <div>
                  <v-icon
                    @click="
                      addEmail.user.to = !addEmail.user.to;
                      addEmail.user.cc = false;
                      addEmail.user.bcc = false;
                    "
                    :icon="
                      addEmail.user.to
                        ? 'mdi-checkbox-marked'
                        : 'mdi-checkbox-blank-outline'
                    "
                  ></v-icon>
                  TO
                </div>
                <div>
                  <v-icon
                    @click="
                      addEmail.user.cc = !addEmail.user.cc;
                      addEmail.user.to = false;
                      addEmail.user.bcc = false;
                    "
                    :icon="
                      addEmail.user.cc
                        ? 'mdi-checkbox-marked'
                        : 'mdi-checkbox-blank-outline'
                    "
                  ></v-icon>
                  CC
                </div>
                <div>
                  <v-icon
                    @click="
                      addEmail.user.bcc = !addEmail.user.bcc;
                      addEmail.user.to = false;
                      addEmail.user.cc = false;
                    "
                    :icon="
                      addEmail.user.bcc
                        ? 'mdi-checkbox-marked'
                        : 'mdi-checkbox-blank-outline'
                    "
                  ></v-icon>
                  BCC
                </div>
                <v-btn @click="addUser()" :disabled="!addEmail.user.searchName"
                  >Hinzufügen</v-btn
                >
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="receivers"
        item-key="name"
        class="elevation-1"
      >
        <template v-slot:item.last_name="{ item }">
          {{ item.last_name + ", " + item.first_name }}
        </template>
        <template v-slot:item.position="{ item }">
          <v-chip size="x-small">{{ item.position }}</v-chip>
          <v-chip size="x-small">{{ item.status }}</v-chip>
        </template>
        <template v-slot:item.to="{ item }">
          <v-icon
            @click="handleCheck(item, 'to', item.to)"
            :icon="
              item.to ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            "
          ></v-icon>
        </template>
        <template v-slot:item.cc="{ item }">
          <v-icon
            @click="handleCheck(item, 'cc', item.cc)"
            :icon="
              item.cc ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            "
          ></v-icon>
        </template>
        <template v-slot:item.bcc="{ item }">
          <v-icon
            @click="handleCheck(item, 'bcc', item.bcc)"
            :icon="
              item.bcc ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
            "
          ></v-icon>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import EmailDialog from "../CommonComponents/EmailComponent/EmailDialog.vue";
const props = defineProps({
  employees: { type: Array, required: true },
});
</script>

<script>
export default {
  data() {
    return {
      emailDialog: false,
      portalEmails: [],
      addEmail: {
        dialog: false,
        user: { searchName: null },
        allEmployees: [],
      },
      chosenEmail: {
        portal: true,
        subject: null,
        to: [],
        cc: [],
        bcc: [],
      },
      receivers: [],
      allFilter: [
        { title: "Keine", value: "none" },
        { title: "To", value: "to" },
        { title: "Cc", value: "cc" },
        { title: "Bcc", value: "bcc" },
      ],
      headers: [
        { title: "To", value: "to", key: "to" },
        { title: "Cc", value: "cc", key: "cc" },
        { title: "Bcc", value: "bcc", key: "bcc" },
        { title: "Nachname", value: "last_name", key: "last_name" },
        { title: "Email", value: "email", key: "email" },
        { title: "Position/Status", value: "position", key: "position" },
      ],
    };
  },
  methods: {
    prepareEmail() {
      this.chosenEmail.subject = null;
      this.chosenEmail.from = "portal@bellcaptain.team";
      this.chosenEmail.friendlyName = "Bellcaptain Team";
      this.chosenEmail.to = [];
      this.chosenEmail.cc = [];
      this.chosenEmail.bcc = [];
      this.receivers.forEach((receiver) => {
        if (receiver.to) {
          this.chosenEmail.to.push(receiver.email);
        }
        if (receiver.cc) {
          this.chosenEmail.cc.push(receiver.email);
        }
        if (receiver.bcc) {
          this.chosenEmail.bcc.push(receiver.email);
        }
      });
      this.$store.state.emailDialog = true;
    },
    handleCheck(item, type, value) {
      item.to = false;
      item.cc = false;
      item.bcc = false;
      item[type] = !value;
    },
    checkAll(all) {
      this.receivers.forEach((receiver) => {
        receiver.to = false;
        receiver.cc = false;
        receiver.bcc = false;
        if (all.value !== "none") {
          receiver[all.value] = true;
        }
      });
    },
    async getAllEmployees() {
      const response = await this.$store.dispatch("getSupabaseEntity", {
        table: "employees",
        order: { key: "last_name", order: "asc" },
      });
      this.addEmail.allEmployees = response.data;
      this.addEmail.allEmployees.forEach((employee) => {
        employee.to = false;
        employee.cc = false;
        employee.bcc = true;
        employee.searchName = `${employee.last_name}, ${employee.first_name}`;
      });
    },
    addUser() {
      this.receivers.push(this.addEmail.user);
      this.addEmail.user = { searchName: null };
    },
  },
  async created() {
    var roleRights = await this.$store.dispatch("checkRole");
    roleRights.emailGroups.forEach((group) => {
      if (group.is_checked && group.email_groups.use_frontend) {
        this.portalEmails.push(group.email_groups);
      }
    });
    this.receivers = JSON.parse(JSON.stringify(this.employees));
    this.receivers.forEach((receiver) => {
      receiver.to = false;
      receiver.cc = false;
      receiver.bcc = true;
    });
    this.getAllEmployees();
  },
};
</script>
